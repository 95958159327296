import Immutable from 'immutable';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { EntityType } from 'const';
import { activeLayer } from 'containers/Project/selectors';
import { layeredRelations as relations } from 'containers/Relations/selectors';
import {
  isDraggingArtboardGroupLayout as isArtboardGroupLayout,
  isDraggingArtboardLayout as isArtboardLayout,
  isDraggingArtboardReusableLayout as isArtboardReusableLayout,
  isDraggingArtboardSSI as isArtboardSSI,
  isDraggingAsset as isAsset,
  isDraggingGroupLayout as isGroupLayout,
  isDraggingLayout as isLayout,
  isDraggingReusableLayout as isReusableLayout,
  isDraggingSSI as isSSI,
} from 'utils/dragAndDrop';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { State } from './models';

export const app = (state): State.IState => state.get('app');
export const dragState = state => app(state).get('dragState');
export const dragSourceType = state => dragState(state).get('type');
export const dragSourceSubtype = state => dragState(state).get('subtype');
export const isResizingColumn = state => app(state).get('isResizingColumn');
export const isResizingLayout = state => app(state).get('isResizingLayout');
export const isResizingRow = state => app(state).get('isResizingRow');
export const isLocked = state => app(state).get('isLocked');
export const activeProcessType = state => app(state).get('activeProcessType');
export const selectionState = state => app(state).get('selectionState');
export const showPreview = state => app(state).get('showPreview');
export const showScreensPanel = state => app(state).get('showScreensPanel');
export const showUpdatedImages = state => app(state).get('showUpdatedImages');
export const showUpdatedModuleBundles = state => app(state).get('showUpdatedModuleBundles');
export const showUpdatedReferences = state => app(state).get('showUpdatedReferences');
export const showUpdatedReusableLayouts = state => app(state).get('showUpdatedReusableLayouts');
export const showUpdatedStoryCards = state => app(state).get('showUpdatedStoryCards');
export const showUpdatedTextCollections = state => app(state).get('showUpdatedTextCollections');
export const dragHotspot = state => app(state).get('dragHotspot');
export const ssiHotspotPosition = state => app(state).get('ssiHotspotPosition');
export const moveableLayoutId = state => app(state).get('moveableLayoutId');
export const lastError = state => app(state).get('lastError');
export const loadingOverlayOptions = state => app(state).get('loadingOverlayOptions');
export const thumbnailsRefreshProgress = state => app(state).get('thumbnailsRefreshProgress');
export const areImagesBeingDownloaded = state => app(state).get('areImagesBeingDownloaded');
export const handleReusableLayoutsEditing = state => app(state).get('handleReusableLayoutsEditing');
export const lastEditedLayoutId = state => app(state).get('lastEditedLayoutId');
export const tempLayoutPreview = state => app(state).get('tempLayoutPreview');
export const activeLayoutIds = state => app(state).get('activeLayoutIds');
export const artboardScale = state => app(state).get('artboardScale');
export const previewScale = state => app(state).get('previewScale');
export const cancelLastUndoState = state => app(state).get('cancelLastUndoState');

export const isDraggingAsset = createSelector(dragSourceType, isAsset);
export const isDraggingLayout = createSelector(dragSourceType, isLayout);
export const isDraggingReusableLayout = createSelector(dragSourceType, isReusableLayout);
export const isDraggingGroupLayout = createSelector(dragSourceType, isGroupLayout);
export const isDraggingArtboardReusableLayout = createSelector(dragSourceType, isArtboardReusableLayout);
export const isDraggingArtboardGroupLayout = createSelector(dragSourceType, isArtboardGroupLayout);
export const isDraggingArtboardLayout = createSelector(dragSourceType, isArtboardLayout);
export const isDraggingSSI = createSelector(dragSourceType, isSSI);
export const isDraggingArtboardSSI = createSelector(dragSourceType, isArtboardSSI);
export const isDraggingAnchor = createSelector(dragSourceSubtype, dragSourceSubtype => dragSourceSubtype === EntityType.ANCHOR);
export const dragHotspotPosition = createSelector(dragHotspot, dragHotspot => dragHotspot.get('position'));
export const dragHotspotGroupLayoutId = createSelector(dragHotspot, dragHotspot => dragHotspot.get('groupLayoutId'));

// to be able not to show action during some operations
export const areActionsDisabled = createSelector(
  [isResizingLayout, isResizingColumn, isResizingRow],
  (isResizingLayout, isResizingColumn, isResizingRow) => isResizingLayout || isResizingColumn || isResizingRow,
);

export const selectedDocumentIds = createSelector(
  [selectionState, relations, activeLayer],
  (selectionState, relations, activeLayer) => {
    const documentIds = new Set<string>();

    const assetDocumentIds = selectionState.get('assetDocumentIds');
    !_.isEmpty(assetDocumentIds) && assetDocumentIds.forEach(documentIds.add, documentIds);

    const artboardRelationIds = selectionState.get('artboardRelationIds');
    artboardRelationIds.forEach((relationId) => {
      const relation = relations.get(relationId);

      if (isRegularRelation(relation)) {
        const documentId = relation.getIn(['documentId', activeLayer]);
        documentId && documentIds.add(documentId);
      }
    });

    return Immutable.Set(documentIds);
  },
);

export const selectedComponentItem = (id: string) => {
  return createSelector(
    [selectedDocumentIds],
    selectedDocumentIds => selectedDocumentIds.has(id),
  );
};

export const getScale = createSelector(
  [showPreview, artboardScale, previewScale],
  (showPreview, artboardScale, previewScale) => showPreview ? previewScale : artboardScale,
);
