import _ from 'lodash';

import { Layer } from 'const';
import * as Models from 'models';
import { isImage } from 'utils/entityType';
import { sum } from 'utils/sum';
import { getMinCellHeight } from './getMinCellHeight';
import { isRegularRelation } from './isRegularRelation';
import { isRowRelation } from './isRowRelation';

export function getMinRowHeight(
  relationId: string,
  relations: Models.LayeredRelationsMap,
  activeLayer: Layer,
  isAutoFitContent?: boolean,
  height?: number,
): number {
  const relation = relations.get(relationId);
  const isImageType = isImage(relation);

  if (isRegularRelation(relation)) {
    return getMinCellHeight(relation, activeLayer, isAutoFitContent, isImageType ? relation.getIn(['styles', activeLayer, 'height']) : height);
  }

  if (isRowRelation(relation)) {
    return _.max(relation.get('relationIds').map(_relationId => getMinRowHeight(_relationId, relations, activeLayer)).toArray());
  }

  const relationIds = (relation as Models.ColumnRelationMap).get('relationIds');
  const rowsHeight = (relation as Models.ColumnRelationMap).getIn(['styles', 'rowsHeight']);

  return sum(relationIds.map((relationId, position) => position === relationIds.size - 1
    ? getMinRowHeight(relationId, relations, activeLayer, isAutoFitContent, height) // NOTE: we resize only last row within nested column
    : rowsHeight.get(position),
  ));
}
