import { CUSTOM_FONT_FACES_ID } from 'const';
import { UIFontFace } from 'models';
import compareFontFacesByWeight from '../utils/styles/compareFontFacesByWeight';

function getFontFaces(UIFontFaces: UIFontFace[]): string[] {
  return UIFontFaces
    .sort((fontFaceA, fontFaceB) => compareFontFacesByWeight(fontFaceA, fontFaceB))
    .map(({ fontFamily, fontStyle, fontWeight, src }) => {
      const fontFamilyString = `font-family: "${fontFamily}";`;
      const fontStyleString = fontStyle ? `font-style: ${fontStyle};` : '';
      const fontWeightString = fontWeight ? `font-weight: ${fontWeight};` : '';
      const fontDisplayString = 'font-display: swap;';
      const srcString = `src: url("${src}");`;

      return `@font-face { ${fontFamilyString} ${fontStyleString} ${fontWeightString} ${fontDisplayString} ${srcString} }`;
    });
}

export function appendCustomFontFaces(UIFontFaces: UIFontFace[], document = window.document): void {
  const fontFaceStyleToRemove = document.getElementById(CUSTOM_FONT_FACES_ID);
  fontFaceStyleToRemove && document.head.removeChild(fontFaceStyleToRemove);

  const fontFaces = getFontFaces(UIFontFaces);
  const fontFaceStyleElement = document.createElement('style');
  fontFaceStyleElement.id = CUSTOM_FONT_FACES_ID;
  fontFaceStyleElement.appendChild(document.createTextNode([...fontFaces].join('\n')));
  document.head.appendChild(fontFaceStyleElement);
}
