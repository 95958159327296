import axios, { AxiosRequestHeaders, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import _ from 'lodash';
import mime from 'mime-types';
import { runSaga } from 'redux-saga';
import store from 'store/store';
import { ALLOWED_IMAGE_MIME_TYPES, API_BASE_URL, EntityType, ErrorType, ProjectType, RenditionType, ResolutionType, ResponseStatus } from 'const';
import * as Models from 'models';
import { ArtboardsJson } from 'services/ArtboardConverter/models';
import { axiosWS } from 'services/axiosWS';
import { Log } from 'services/logger/models';
import { getChecksum } from 'utils/getChecksum';
import fetchAsmol from './fetchAsmol';
import { handleSagaError } from './handleError';
import SessionKeepAliveManager from './SessionKeepAliveManager';

function modifyRequestHeaders(headers: AxiosRequestHeaders): AxiosRequestHeaders {
  headers['user-id'] = sessionStorage.getItem('pm-userId');
  headers.Authorization = sessionStorage.getItem('pm-sessionId');
  // Secure Headers
  headers['Strict-Transport-Security'] = 'max-age=31536000';
  headers['X-Frame-Options'] = 'deny';
  headers['X-XSS-Protection'] = '1; mode=block';

  return headers;
}

async function sessionKeepAlive(): Promise<void> {
  const url = `${API_BASE_URL}/session/keep-alive`;
  await axios.create().get(url, {
    headers: modifyRequestHeaders({} as AxiosRequestHeaders),
  });
}

export const sessionManager = new SessionKeepAliveManager({
  checkHandler: sessionKeepAlive,
  errorHandler: (): void => {
    runSaga(
      store,
      handleSagaError,
      {
        type: ErrorType.PromoMats.INVALID_SESSION_ID,
        message: 'Session has expired',
      },
      'SessionManager',
      'validate session',
    );
  },
});

function checkErrorFromResponse(response: Models.Response<unknown>): Models.AssemblerError | null {
  if (!response || response.responseStatus !== ResponseStatus.FAILURE) {
    return null;
  }

  const type = _.get(response, 'error.type');
  const message = _.get(response, 'error.message', 'Server error');

  return { type, message };
}

async function checkErrorFromBlobResponse(error): Promise<Models.AssemblerError | void> {
  const blob = _.get(error, 'response.data');

  if (!(blob instanceof Blob)) {
    return;
  }

  let response;
  try {
    const json = await new Response(blob).text();
    response = JSON.parse(json);
  } catch (error) {
    return;
  }

  const customError = checkErrorFromResponse(response);

  if (customError) {
    return customError;
  }
}

axios.interceptors.response.use(
  async (response) => {
    sessionManager.start();
    // TODO: should be enabled when backend validates SESSION_ID
    // sessionManager.setAlive();
    const { data } = response;
    const error = checkErrorFromResponse(data);
    if (error) {
      return Promise.reject(error);
    }

    return response;
  },
  async (error) => {
    const customError = await checkErrorFromBlobResponse(error);
    if (customError) {
      return Promise.reject(customError);
    }

    // absence of response is considered like internet connection error
    const { data = {}, status = 0 } = error.response || { data: { error: { type: ErrorType.Assembler.NO_INTERNET_CONNECTION } } };
    // AWS authorizer returns either 403 or 401 status code depended on the validity of the session ID
    const authorizationErrorStatus = [401, 403];
    const { message, type = authorizationErrorStatus.includes(status) ? ErrorType.PromoMats.INVALID_SESSION_ID : null } = _.get(data, 'error', {});

    // we expect these errors will be processed in sagas
    if (type === ErrorType.PromoMats.INVALID_SESSION_ID) {
      sessionManager.setTerminated(false);
    }

    const receivedErrorMessage = _([message, error.message, data.message]).compact().map(msg => _.replace(msg, /\.$/, '')).join(', ');
    const errorMessage = receivedErrorMessage && `${receivedErrorMessage.replace(/\.$/, '')}.`;
    const errorStatusCode = status ? ` Status code: ${status}.` : '';
    const fullErrorMessage = `${errorMessage}${errorStatusCode}`;

    return Promise.reject({ type, message: fullErrorMessage });
  },
);

axios.interceptors.request.use((config) => {
  if (config.headers['ignore-request-interceptor']) {
    delete config.headers['ignore-request-interceptor'];

    return config;
  }
  config.headers = modifyRequestHeaders(config.headers);

  return config;
});

// BASE ROUTE

export async function getDocumentTypes(): Promise<Models.Response<Models.DocumentTypes>> {
  const url = `${API_BASE_URL}/document-types`;
  const { data } = await axios.get(url);

  return data;
}

export async function getCountryAbbreviations(): Promise<Models.Response<Models.CountryAbbreviations>> {
  const url = `${API_BASE_URL}/country-abbreviations`;
  const response = await fetchAsmol(url, {
    cache: 'default',
  });

  return response.json();
}

export async function getUploadUrl(fileName: string, mimeType: string): Promise<Models.SuccessResponse<string>> {
  const url = new URL(`${API_BASE_URL}/upload-url`);

  url.searchParams.append('fileName', fileName);
  url.searchParams.append('mimeType', mimeType);

  const { data } = await axios.get<Models.Response<string>>(url.href);

  if (data.responseStatus === ResponseStatus.SUCCESS) {
    return data;
  } else {
    throw new Error(data.error.message);
  }
}

export async function getArtboardPreview(
  artboardJson: ArtboardsJson,
  fonts: Models.UIFontFace[],
  projectType: ProjectType,
  helperFileName: string,
): Promise<Models.Response<Models.ArtboardHtmlAndSize>> {
  const url = `${API_BASE_URL}/artboard-preview`;
  const body = {
    artboardJson,
    fonts,
    projectType,
    helperFileName,
  };

  const { data } = await axiosWS.post(url, body);

  return data;
}

export async function getThumbnails(
  artboardJson: ArtboardsJson,
  helperFileName: string,
  fonts: Models.UIFontFace[],
): Promise<Models.Response<Record<string, string>>> {
  const url = `${API_BASE_URL}/thumbnails`;
  const body = {
    artboardJson,
    helperFileName,
    fonts,
  };

  const { data } = await axiosWS.post(url, body);

  return data;
}

export async function getProjectPdf(
  artboardsJson: ArtboardsJson,
  projectPdfName: string,
  thumbnailsInfo: Models.ThumbnailsInfo,
  fonts: Models.UIFontFace[],
  projectType: ProjectType,
  helperFileName: string,
): Promise<Models.Response<string>> {
  const url = `${API_BASE_URL}/project-pdf`;
  const body = {
    artboardsJson,
    projectPdfName,
    thumbnailsInfo,
    fonts,
    projectType,
    helperFileName,
  };

  const { data } = await axiosWS.post(url, body);

  return data;
}

export function postPerformanceLog(body: Log): Promise<AxiosResponse> {
  const url = `${API_BASE_URL}/performance`;

  return axios.post(url, body);
}

// DOCUMENTS ROUTE

export async function getDocumentAvailability(documentId: number): Promise<Models.Response<boolean>> {
  const url = `${API_BASE_URL}/documents/${documentId}/availability`;

  const { data } = await axios.get(url);

  return data;
}

export async function getDocumentPreview(documentId: number, checksum: string): Promise<Models.Response<number[][]>> {
  const url = new URL(`${API_BASE_URL}/documents/${documentId}/preview`);
  checksum && url.searchParams.append('checksum', checksum);

  // BE returns an array of serialized buffers that presented as uint8 arrays
  const { data } = await axiosWS.get(url.href);

  return data;
}

export async function getDocumentDuplications(
  checksum: string,
  language = '',
  country = '',
): Promise<Models.Response<Models.DocumentDuplicationsResponseData>> {
  const url = new URL(`${API_BASE_URL}/documents/duplication`);

  url.searchParams.append('checksum', checksum);
  url.searchParams.append('language', language);
  url.searchParams.append('country', country);

  const { data } = await axios.get(url.href);

  return data;
}

export async function updateReferenceCitationDocument(
  documentId: number,
  referenceCitation: Models.ReferenceCitation,
): Promise<Models.Response<Models.ReferenceCitation>> {
  const url = `${API_BASE_URL}/documents/${documentId}/update`;
  const { data } = await axiosWS.post(
    url,
    {
      document: referenceCitation,
    },
  );

  return data;
}

export async function getAlreadyTranslatedReusableLayouts(
  reusableLayoutDocumentIds: number[],
  language: string,
  country: string,
): Promise<Models.Response<Models.TranslatedLayoutsResponseData>> {
  const url = new URL(`${API_BASE_URL}/documents/translated`);

  url.searchParams.append('language', language);
  url.searchParams.append('country', country);
  url.searchParams.append('documentIds', reusableLayoutDocumentIds.join(','));

  const { data } = await axiosWS.get(url.href);

  return data;
}

export async function getDocumentRendition(documentId: number, checksum: string, rendition: RenditionType): Promise<Blob> {
  const url = new URL(`${API_BASE_URL}/documents/${documentId}/rendition`);

  url.searchParams.append('rendition', rendition);
  checksum && url.searchParams.append('checksum', checksum);

  const response = await fetchAsmol(url.toString(), {
    headers: {
      Accept: ALLOWED_IMAGE_MIME_TYPES,
    },
    cache: 'default',
  });

  return response.blob();
}

// IMAGE ROUTE

export async function updateImageDocument(
  imageDocumentId: number,
  imageData: Models.ImageDataToUpload,
): Promise<Models.Response<Models.Image>> {
  const imageNames = await getImageNames(imageData);
  const url = `${API_BASE_URL}/image/${imageDocumentId}/update`;
  const { data } = await axiosWS.put(url, { imageNames });

  return data;
}

// ASSETS-COLLECTION ROUTE
interface AssetsCollectionResponse {
  documents: Models.CombinedDocuments;
  entities: Models.StoryCardEntities;
}

export async function getAssetsCollection(documentId: number): Promise<Models.Response<AssetsCollectionResponse>> {
  const url = `${API_BASE_URL}/assets-collection/${documentId}`;
  const { data } = await axiosWS.get(url);

  return data;
}

// PACKAGE ROUTE

export async function getPackageForHtml(
  artboardsJson: ArtboardsJson,
  archiveName: string,
  fonts: Models.UIFontFace[],
  projectType: ProjectType,
  helperFileName: string,
): Promise<Models.Response<Models.PackageHtmlUrlAndSize>> {
  const url = `${API_BASE_URL}/package/html`;
  const body = {
    artboardsJson,
    archiveName,
    fonts,
    projectType,
    helperFileName,
  };

  const { data } = await axiosWS.post(url, body);

  return data;
}

export async function getIPadPackage(
  artboardsJson: ArtboardsJson,
  fonts: Models.UIFontFace[],
  thumbnailsInfo: Models.ThumbnailsInfo,
  archiveName: string,
  projectType: ProjectType,
  helperFileName: string,
): Promise<Models.Response<string>> {
  const url = `${API_BASE_URL}/package/ipad`;
  const body = {
    artboardsJson,
    fonts,
    thumbnailsInfo,
    archiveName,
    projectType,
    helperFileName,
  };

  const { data } = await axiosWS.post(url, body);

  return data;
}

export async function getTranslationPackage(
  archiveName: string,
  rootDocument: Models.RootDocument,
  documents: Models.CombinedDocuments,
  textComponentHtmlById: Record<string, string>,
  callToActionsInnerTextById: Record<string, string>,
  projectPdfUrl: string,
): Promise<Models.Response<string>> {
  const url = `${API_BASE_URL}/translation/export`;
  const body = {
    rootDocument,
    archiveName,
    documents,
    textComponentHtmlById,
    callToActionsInnerTextById,
    projectPdfUrl,
  };

  const { data } = await axiosWS.post(url, body);

  return data;
}

interface ImportTranslationPackageResult {
  documentsMap: Models.CombinedDocuments;
}

export async function importTranslationPackage(
  translationPackage: File,
  projectNumber: string,
  projectId: number,
  documents: Models.CombinedDocuments,
): Promise<Models.Response<ImportTranslationPackageResult>> {
  const translationPackageName = await uploadFile(translationPackage);
  const url = `${API_BASE_URL}/translation/import`;
  const body = {
    packageName: translationPackageName,
    projectNumber,
    projectId,
    documents,
  };
  const { data } = await axiosWS.post(url, body);

  return data;
}

// PROJECTS ROUTE

export async function getRootDocument(rootDocumentId: number): Promise<Models.Response<Models.RootDocument>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}`;
  const { data } = await axios.get<Models.Response<Models.RootDocument>>(url);

  return data;
}

export async function getProjectAssets(rootDocumentId: number, suppressTranslation: boolean): Promise<Models.Response<Models.ProjectAssets>> {
  const url = new URL(`${API_BASE_URL}/projects/${rootDocumentId}/assets`);

  url.searchParams.append('suppressTranslation', `${suppressTranslation}`);

  const { data } = await axiosWS.get<Models.Response<Models.ProjectAssets>>(url.href);

  return data;
}

export async function convertAssets<T extends Models.ProjectAssetsToUpload>(
  projectAssets: T,
): Promise<Models.Response<Models.ProjectAssetsToUpload>> {
  const url = `${API_BASE_URL}/convert-assets`;
  const { data } = await axiosWS.post<Models.Response<Models.ProjectAssetsToUpload>>(url, { projectAssets });

  return data;
}

export async function getProjectFileDetails(rootDocumentId: number): Promise<Models.Response<Models.ProjectFileDetails>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/project-file`;
  const { data } = await axios.get<Models.Response<Models.ProjectFileDetails>>(url);

  return data;
}

export async function getMasterScreenData(rootDocumentId: number): Promise<Models.Response<Models.MasterScreenData>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/master-screen`;
  const { data } = await axios.get<Models.Response<Models.MasterScreenData>>(url);

  return data;
}

export async function getProjectDetails(rootDocumentId: number) {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/details`;
  const { data } = await axios.get(url);

  return data;
}

export async function uploadJson(
  rootDocumentId: number,
  projectAssets: Models.ProjectAssetsToUpload,
): Promise<Models.Response<Models.Document<EntityType.PROJECT_FILE>>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/json`;
  const { data } = await axiosWS.post<Models.Response<Models.Document<EntityType.PROJECT_FILE>>>(url, { projectAssets });

  return data;
}

export async function createLayout(
  rootDocumentId: number,
  layoutAssets: Models.LayoutAssets,
  layoutOptions: Models.SaveReusableLayoutOptions,
  documentOptions: Models.DocumentCreationOptions,
  originalDocumentId?: number,
): Promise<Models.Response<Models.ReusableLayout>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/layout`;
  const { data } = await axiosWS.post<Models.Response<Models.ReusableLayout>>(url, {
    layoutAssets,
    originalDocumentId,
    ...layoutOptions,
    ...documentOptions,
  });

  return data;
}

export async function createGroupLayout(
  rootDocumentId: number,
  groupLayoutAssets: Models.GroupLayoutAssets,
  name: string,
  documentOptions: Models.DocumentCreationOptions,
  originalDocumentId?: number,
): Promise<Models.Response<Models.GroupLayoutDocument>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/group-layout`;
  const { data } = await axiosWS.post<Models.Response<Models.GroupLayoutDocument>>(url, {
    groupLayoutAssets,
    originalDocumentId,
    name,
    ...documentOptions,
  });

  return data;
}

export async function uploadPackageAndPdf(
  rootDocumentId: number,
  artboardsJson: ArtboardsJson,
  fonts: Models.UIFontFace[],
  projectType: string,
  helperFileName: string,
  thumbnailsInfo: Models.ThumbnailsInfo,
  archiveName: string,
): Promise<Models.Response<Models.PackageThumbnailsAndSize>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/package`;
  const body = {
    artboardsJson,
    fonts,
    thumbnailsInfo,
    projectType,
    helperFileName,
    archiveName,
  };

  const { data } = await axiosWS.post(url, body);

  return data;
}

export async function getRefreshedProject(
  rootDocumentId: number,
  documents: Models.CombinedDocuments,
  documentInternalIdsOnArtboards: string[],
): Promise<Models.Response<Models.RefreshResponseData>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/refresh`;
  const { data } = await axiosWS.post(url, {
    documents,
    documentInternalIdsOnArtboards,
  });

  return data;
}

export async function uploadReferenceCitation(
  rootDocumentId: number,
  text: string,
  documentOptions: Models.DocumentCreationOptions,
): Promise<Models.Response<Models.ReferenceCitation>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/reference-citation`;
  const { data } = await axiosWS.post(url, { text, ...documentOptions });

  return data;
}

export async function uploadImage(
  rootDocumentId: number,
  imageData: Models.ImageDataToUpload,
  documentOptions: Models.DocumentCreationOptions,
): Promise<Models.Response<Models.Image>> {
  const imageNames = await getImageNames(imageData, false);
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/image`;
  const body = {
    imageNames,
    ...documentOptions,
    ..._.omit(imageData, 'imageFiles'),
  };
  const { data } = await axiosWS.post(url, body);

  return data;
}

export async function linkDocumentToProject(
  rootDocumentId: number,
  documentToLinkId: number,
  documentToLinkEntityType: EntityType,
  majorVersion: number,
  minorVersion: number,
): Promise<void> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/link/${documentToLinkId}`;

  await axios.post(
    url,
    {
      majorVersion,
      minorVersion,
      entityType: documentToLinkEntityType,
    },
  );
}

export async function linkDocumentsToProject(
  rootDocumentId: number,
  documents: Models.CombinedDocuments,
): Promise<Models.Response<{ successfullyLinkedDocumentIds: number[]; notUpdatedLayoutIds: number[] }>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/documents`;
  const { data } = await axiosWS.post(url, { documents });

  return data;
}

export async function syncAssets(
  rootDocumentId: number,
  documents: Models.CombinedDocuments,
  documentInternalIdsOnArtboards: string[],
): Promise<Models.Response<Models.SyncResponseData>> {
  const url = `${API_BASE_URL}/projects/${rootDocumentId}/sync-assets`;
  const { data } = await axiosWS.post(url, {
    documents,
    documentInternalIdsOnArtboards,
  });

  return data;
}

export async function getProjectLinkedDocuments(documentId: number): Promise<Models.Response<Models.CombinedDocument[]>> {
  const url = `${API_BASE_URL}/projects/${documentId}/documents`;
  const { data } = await axios.get(url);

  return data;
}

export async function uploadFile(file: File, defaultMime?: string, headers: Partial<RawAxiosRequestHeaders> = {}): Promise<string> {
  const { name, type } = file;
  const mimeType = type || mime.lookup(name) || defaultMime;
  if (!mimeType) {
    throw new Error(`Unable to get a mimeType from type:${type} or name: ${name}`);
  }

  const { data: uploadUrl } = await getUploadUrl(name, mimeType);

  if (!uploadUrl) {
    throw new Error('Unable to obtain an upload URL');
  }

  await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': mimeType,
      'ignore-request-interceptor': true,
      ...headers,
    },
  });

  return decodeURIComponent(new URL(uploadUrl).pathname.replace('/', ''));
}

async function getImageNames(imageData: Models.ImageDataToUpload, fullPath = true): Promise<Record<ResolutionType, string>> {
  const imageNames = {} as Record<ResolutionType, string>;
  const imageChecksums = {} as Record<ResolutionType, string>;

  for (let resolution of Object.keys(ResolutionType)) {
    resolution = resolution.toLowerCase();
    const imageFile = imageData.imageFiles[resolution];
    if (imageFile) {
      const checksum = await getChecksum(imageFile);

      if (!imageChecksums[checksum]) {
        const filePath = await uploadFile(imageFile, 'image/jpeg');
        imageChecksums[checksum] = fullPath ? filePath : filePath.replace('requests/', '');
      }

      imageNames[resolution] = imageChecksums[checksum];
    }
  }

  return imageNames;
}
