import { CSSProperties } from 'react';
import { AssetAlignmentType, TextHorizontalAlignmentType, TextLineHeightValue } from 'const';
import { Padding, StyleRanges, TextHorizontalAlignment, TextLineHeight } from 'models';

export interface EditorDependentStyles {
  bulletColor?: StyleRanges;
  fontColor: StyleRanges;
  fontSize: StyleRanges;
  fontFamily: StyleRanges;
  fontStyle: StyleRanges;
  fontWeight?: StyleRanges;
  characterStyleName?: StyleRanges;
  lineHeight: TextLineHeight;
  padding?: Padding;
  alignment: {
    horizontal: TextHorizontalAlignment;
  };
}

export enum BrandStyleProp {
  ALIGNMENT = 'alignment',
  BACKGROUND_COLOR = 'backgroundColor',
  BULLET_COLLOR = 'bulletColor',
  CHARACTER_STYLE = 'characterStyle',
  CHARACTER_STYLE_NAME = 'characterStyleName',
  COLOR = 'color',
  FONT_COLOR = 'fontColor',
  FONT_FAMILY = 'fontFamily',
  FONT_SIZE = 'fontSize',
  FONT_STYLE = 'fontStyle',
  FONT_WEIGHT = 'fontWeight',
  HORIZONTAL = 'horizontal',
  LINE_HEIGHT = 'lineHeight',
  PADDING = 'padding',
  TEXT_ALIGN = 'textAlign',
  TEXT_TRANSFORM = 'textTransform',
  VERTICAL_ALIGN = 'verticalAlign',
  VERTICAL_ALIGNMENT = 'verticalAlignment',
  VERTICAL = 'vertical',
}

export interface BrandStyleObj {
  [BrandStyleProp.BACKGROUND_COLOR]: string;
  [BrandStyleProp.BULLET_COLLOR]: string;
  [BrandStyleProp.COLOR]: string;
  [BrandStyleProp.FONT_FAMILY]: string;
  [BrandStyleProp.FONT_SIZE]: string;
  [BrandStyleProp.LINE_HEIGHT]: TextLineHeightValue;
  [BrandStyleProp.PADDING]: string;
  [BrandStyleProp.TEXT_ALIGN]: TextHorizontalAlignmentType;
  [BrandStyleProp.VERTICAL_ALIGN]: AssetAlignmentType;
  [BrandStyleProp.TEXT_TRANSFORM]?: CSSProperties['textTransform'];
}

export type BrandStyleObjMap = DeepIMap<BrandStyleObj>;

export interface StyleBreakpoint {
  type: string;
  style: BrandStyleObj;
}

export interface BrandStyle {
  id: string;
  name: string;
  breakpoints: StyleBreakpoint[];
}
export type BrandStylesRecord = Record<string, BrandStyle>;

export type BrandStyleMap = DeepIMap<BrandStyle>;
export type BrandStylesMap = DeepIMap<BrandStylesRecord>;

export type BrandStyles = BrandStyle[];

export type BrandStylesList = DeepIMap<BrandStyles>;
