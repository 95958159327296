import createFocusPlugin from 'draft-js-focus-plugin';

import { IconType } from 'components/Icon/constants';
import { TitleWithNamespace as Title } from 'components/Toolbar/constants';
import { TextHorizontalAlignmentType } from './TextAlignment';

// Single constants
// TODO: [#defaultStyles] we need to move all default styles to 1 place
export const CallToActionLineHeightModifier = 1.2;
export const LIST_MAX_DEPTH = 4;
export const SCRIPT_BASIC_FONT_REDUCTION = 0.75;

export enum HandleValue {
  HANDLED = 'handled',
  NOT_HANDLED = 'not-handled',
}

// Enums
export enum DraftEntity {
  ABBREVIATION = 'ABBR',
  LINK = 'LINK',
  TOKEN = 'TOKEN',
  PHOTO = 'PHOTO',
  IMAGE = 'IMAGE',
  REFERENCE = 'REFERENCE',
  NO_WRAP = 'NO_WRAP',
}

export enum TextToken {
  CUSTOM_TEXT = '{{customText',
  INSERT_EMAIL_FRAGMENTS = '{{insertEmailFragments',
  ADD_TO_CALENDAR = '{{addToCalendar',
}

export enum DraftEntityMutability {
  MUTABLE = 'MUTABLE',
  IMMUTABLE = 'IMMUTABLE',
  SEGMENTED = 'SEGMENTED',
}

export enum DraftEditorStateChangeType {
  ADJUST_DEPTH = 'adjust-depth',
  APPLY_ENTITY = 'apply-entity',
  CHANGE_INLINE_STYLE = 'change-inline-style',
  CHANGE_BLOCK_DATA = 'change-block-data',
}

export enum EditorChangeType {
  SET_BULLET_COLOR = 'set-bullet-color',
  ADD_REFERENCE = 'add-reference',
  DELETE_CHARACTER = 'delete-character',
  BACKSPACE_CHARACTER = 'backspace-character',
  INSERT_CHARACTERS = 'insert-characters',
  INSERT_FRAGMENT = 'insert-fragment',
  UNDO = 'undo',
  REDO = 'redo',
  REMOVE_RANGE = 'remove-range',
  SPLIT_BLOCK = 'split-block',
}

export enum EditorKeyBindingCommand {
  UNDO = 'undo_',
  REDO = 'redo',
  BACKSPACE = 'backspace',
}

// Custom inline styles
export enum StylePrefix {
  FONT_COLOR_NAME = '_color_name_', // we have "color_name", not "font_color_name" here cause prefixes can't have the same beginning
  FONT_COLOR = '_font_color_',
  FONT_COLOR_TINT = '_font_tint_',
  BULLET_COLOR = '_bullet_color_hex',
  BULLET_COLOR_NAME = '_bullet_color_name_',
  BULLET_COLOR_TINT = '_bullet_color_tint_',
  FONT_FAMILY = '_font_family_',
  FONT_SIZE = '_font_size_',
  FONT_STYLE = '_font_style_',
  FONT_WEIGHT = '_font_weight_',
  CHARACTER_STYLE_NAME = '_character_style_name_',
}

// If more than one same style exist in current EditorState Selection
export enum MultiCustomStyle {
  FONT_COLOR = '#000000',
  FONT_SIZE = '-',
  FONT_FAMILY = '-',
}

export enum FontSizeLimit {
  MIN = 1,
  MAX = 72,
}

export enum InlineStylesForToggling {
  REGULAR = '_font_weight_400',
  BOLD = '_font_weight_700',
  NORMAL = '_font_style_normal',
  ITALIC = '_font_style_italic',
}

export enum OldInlineStyle {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
}

export enum InlineStyle {
  BOLD = '_font_weight_700',
  ITALIC = '_font_style_italic',
  UNDERLINE = 'UNDERLINE',
}

export enum InlineFontStyleNoPrefix {
  ITALIC = 'italic',
  NORMAL = 'normal',
  REGULAR = '400',
  BOLD = '700',
}

export enum ScriptType {
  SUPERSCRIPT = 'SUPERSCRIPT',
  SUBSCRIPT = 'SUBSCRIPT',
}

// TODO: [#defaultStyles] we need to move all default styles to 1 place
export enum DefaultCustomStyle {
  FONT_COLOR = '#000000',
  FONT_FAMILY = 'Arial',
  FONT_SIZE = 14,
}

export enum FontFamily {
  CONDENSED = 'Condensed',
  ULTRA_CONDENSED = 'Ultra Condensed',
  EXTENDED = 'Extended',
}

export enum BlockDataKey {
  ANCHOR = 'anchor',
  LINE_HEIGHT = 'lineHeight',
}

// Helpers
export const DraftEditorPlugins = [
  createFocusPlugin(),
];

export const FontSizes: number[] = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
// TODO: think of making Reference Toolbar compatible with new convention styles and maybe remove this
export const OLD_CHAR_STYLES = [
  { label: IconType.TOOLBAR_BOLD, style: OldInlineStyle.BOLD, title: Title.FONT_DECORATION_BOLD },
  { label: IconType.TOOLBAR_ITALIC, style: OldInlineStyle.ITALIC, title: Title.FONT_DECORATION_ITALIC },
  { label: IconType.TOOLBAR_UNDERLINE, style: OldInlineStyle.UNDERLINE, title: Title.FONT_DECORATION_UNDERLINE },
];

export const CHAR_STYLES = [
  { label: IconType.TOOLBAR_BOLD, style: InlineStyle.BOLD, title: Title.FONT_DECORATION_BOLD },
  { label: IconType.TOOLBAR_ITALIC, style: InlineStyle.ITALIC, title: Title.FONT_DECORATION_ITALIC },
  { label: IconType.TOOLBAR_UNDERLINE, style: InlineStyle.UNDERLINE, title: Title.FONT_DECORATION_UNDERLINE },
];

export const DEFAULT_SCRIPT_FONT_SIZE = `${SCRIPT_BASIC_FONT_REDUCTION * 100}%`;
export const CUSTOM_SCRIPT_STYLES = {
  [ScriptType.SUPERSCRIPT]: {
    position: 'relative',
    verticalAlign: 'baseline',
    top: '-0.5em',
    fontSize: DEFAULT_SCRIPT_FONT_SIZE,
  },
  [ScriptType.SUBSCRIPT]: {
    position: 'relative',
    verticalAlign: 'baseline',
    top: '0.3em',
    fontSize: DEFAULT_SCRIPT_FONT_SIZE,
  },
};

export const SCRIPT_CONTROLS = [
  {
    label: IconType.TOOLBAR_SUPERSCRIPT,
    style: ScriptType.SUPERSCRIPT,
    title: Title.FONT_SUPERSCRIPT,
  },
  {
    label: IconType.TOOLBAR_SUBSCRIPT,
    style: ScriptType.SUBSCRIPT,
    title: Title.FONT_SUBSCRIPT,
  },
];

export const LIST_STYLES = [
  // { label: IconType.TOOLBAR_ORDERED_LIST, style: 'ordered-list-item' }, Will be use in future implementation. Please do not remove it.
  {
    label: IconType.TOOLBAR_UNORDERED_LIST,
    style: TextHorizontalAlignmentType.UNORDERED_LIST,
    title: Title.TEXT_ALIGNMENT_HORIZONTAL_UNORDERED_LIST,
  },
];
