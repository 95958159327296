import classNames from 'classnames';
import parse from 'html-react-parser';
import _ from 'lodash';
import * as React from 'react';
import { Button } from 'react-bootstrap';

import { ASSEMBLER_HELP, ErrorHelpMessageType } from 'const';
import { NotificationWindowOptions } from 'models';
import { intlGet } from 'utils/intlGet';
import { NotificationProps } from './models';
import styles from './styles.module.scss';

const Notification: React.FunctionComponent<NotificationProps> = (props) => {
  const { options, modalWindowId, downloadIssueReport, hideModal } = props;
  const {
    title,
    message,
    listOfErrorMessages,
    subMessage,
    helpType,
    helpMessage,
    helpLink,
    issueReportAvailable,
  } = options.toJS() as NotificationWindowOptions;

  const onCloseClick = (): void => {
    hideModal(modalWindowId);
  };

  const helpMessageElement = getHelpMessage(helpType, helpMessage, helpLink);
  const reportLinkTitle = intlGet('NotificationWindow.Link', 'DownloadIssueReport');
  const [firstMessage, secondMessage] = (message || '').split('|');

  return (
    <div className={styles.Notification}>
      <div className={styles.title}>{title}</div>
      {message &&
        <div className={classNames(styles.message, { [styles.message__withHelp]: !!helpMessageElement })}>
          {firstMessage && <div>{firstMessage}</div>}
          {!_.isEmpty(listOfErrorMessages) &&
            (<div className={styles.orderedListWrapper}>
              <ol className={styles.orderedList}>
                {listOfErrorMessages.map((message, index) => <li key={index}>{message}</li>)}
              </ol>
            </div>)
          }
          {secondMessage && <div className={styles.secondMessage}>{secondMessage}</div>}
        </div>
      }
      {subMessage && <div className={styles.subMessage}>{subMessage}</div>}
      {helpMessageElement}
      <Button className={styles.button} onClick={onCloseClick}>{intlGet('NotificationWindow.Button', 'Close')}</Button>
      {issueReportAvailable && <span className={styles.reportLink} onClick={downloadIssueReport}>{reportLinkTitle}</span>}
    </div>
  );
};

function getHelpMessage(helpType?: ErrorHelpMessageType, helpMessage?: string, helpLink = ASSEMBLER_HELP): JSX.Element {
  let content: JSX.Element = null;

  switch (helpType) {
    case ErrorHelpMessageType.HELP_OPTIONS: {
      content = <>Click <a href={helpLink} target="_blank">here</a> for help options.</>;
      break;
    }

    case ErrorHelpMessageType.CONTACT_SUPPORT: {
      const message = helpMessage || intlGet('Notification.PromoMatsError.HelpMessage', 'TryAgainLater');
      content = <>{parse(message)} If the problem persists, <a href={helpLink} target="_blank">contact support</a>.</>;
      break;
    }

    case ErrorHelpMessageType.SAVE_LOCALLY: {
      const message = intlGet('Notification.AssemblerError.HelpMessage', 'TryAgainLaterOrUploadYourProjectToPromoMats');
      content = <>{message} or <a href={helpLink} target="_blank">contact support</a>.</>;
      break;
    }
  }

  if (!content) {
    return null;
  }

  return <div className={styles.help}>{content}</div>;
}

export default Notification;
