import Draft, { CharacterMetadata, ContentBlock, ContentState, DraftInlineStyle, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import { OrderedSet, List } from 'immutable';
import _ from 'lodash';

import { MutableRefObject } from 'react';
import * as Constants from 'const';
import { DefaultTextStyles } from 'const/Styles';
import * as Models from 'models';
import { getFontFaceStringWrapper } from 'utils/brandStyles';
import { colorWithTint } from 'utils/converters';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import * as inlineStylesUtils from 'utils/inlineStyles';
import { toPx } from 'utils/toPx';
import { getLineHeightMap } from './blockData';
import { getAlignmentMap } from './blockType';
import { getSelectedBlocks, setFullSelection } from './selection';

function getCharacterStylesWithPrefix(
  block: ContentBlock,
  blockStartCharacter: number,
  blockEndCharacter: number,
  stylePrefix: string,
): OrderedSet<string> {
  let characterStylesResultList = OrderedSet<string>();

  for (let i = blockStartCharacter; i <= blockEndCharacter; i++) {
    const characterStyleList = block.getInlineStyleAt(i);
    characterStyleList.forEach((style) => {
      if (style?.startsWith(stylePrefix)) {
        characterStylesResultList = characterStylesResultList.add(style);
      }
    });
  }

  return characterStylesResultList;
}

function getAllStylesWithPrefixInCurrentSelection(editorState: EditorState, stylePrefix: Constants.StylePrefix): DraftInlineStyle {
  const selection = editorState.getSelection();
  const selectionStart = selection.isCollapsed() ? Math.max(selection.getStartOffset() - 1, 0) : selection.getStartOffset();
  const selectionEnd = selection.getEndOffset() - 1;
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();

  const blocks = getSelectedBlocks(selection, editorState.getCurrentContent());

  return blocks.reduce(
    (memo: DraftInlineStyle, block) => {

      if (block && memo) {
        const blockStartCharacter = block.getKey() === startKey ? selectionStart : 0;
        const blockEndCharacter = block.getKey() === endKey ? selectionEnd : block.getLength();

        return memo.union(getCharacterStylesWithPrefix(block, blockStartCharacter, blockEndCharacter, stylePrefix).toArray());
      }

      return memo;

    },
    OrderedSet<string>() as unknown as DraftInlineStyle, // Fix for issue with different versions of ImmutableJS in Assembler and DraftJS
  );
}

export function getResetStateForMultiOptionStyle(editorState: EditorState, stylePrefix: Constants.StylePrefix): ContentState {
  const selection = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const stylesToBeRemoved = getAllStylesWithPrefixInCurrentSelection(editorState, stylePrefix);
  let nextContentState = currentContent;

  stylesToBeRemoved.forEach((style) => {
    nextContentState = Modifier.removeInlineStyle(nextContentState, selection, style);
  });

  return nextContentState;
}

/**
 *  Function return new EditorState where custom styles with provided prefix was removed
 *  and apply new style to selected characters.
 *  Consider selection and override styles.
 *  */
export function toggleCustomInlineStyle(editorState: EditorState, style: string, stylePrefix: Constants.StylePrefix): EditorState {
  // Reset old font styles for selection
  const nextContentState = getResetStateForMultiOptionStyle(editorState, stylePrefix);
  let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

  // Set new styles to selected characters
  style && (nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, style));

  // Remove rudimental custom fonts in inlineOverrideStyles
  const inlineStyleOverride = nextEditorState.getInlineStyleOverride();
  if (inlineStyleOverride) {
    const filteredOverrideStyle = inlineStyleOverride.filter(
      overrideStyle => overrideStyle === style || !overrideStyle?.startsWith(stylePrefix),
    ) as DraftInlineStyle;
    nextEditorState = EditorState.setInlineStyleOverride(nextEditorState, filteredOverrideStyle);
  }

  return nextEditorState;
}

export function removeAllInlineStylesForSelection(editorState: EditorState): EditorState {
  const styles = [...Constants.OLD_CHAR_STYLES, ...Constants.SCRIPT_CONTROLS].map(({ style }) => style);

  const contentState = editorState.getCurrentContent();
  const contentWithoutStyles = styles.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(
        newContentState,
        editorState.getSelection(),
        style,
      ),
    contentState,
  );

  return EditorState.push(
    editorState,
    contentWithoutStyles,
    Constants.DraftEditorStateChangeType.CHANGE_INLINE_STYLE,
  );
}

export function removeSpecifiedInlineStylesForSelection(editorState: EditorState, styles: string[]): EditorState {
  const contentState = editorState.getCurrentContent();
  const contentWithoutStyles = styles.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(
        newContentState,
        editorState.getSelection(),
        style,
      ),
    contentState,
  );

  return EditorState.push(
    editorState,
    contentWithoutStyles,
    Constants.DraftEditorStateChangeType.CHANGE_INLINE_STYLE,
  );
}

export function isStyleAppliedToAllSelection(editorState: EditorState, style: string): boolean {
  const selection = editorState.getSelection();

  if (selection.isCollapsed()) {
    return editorState.getCurrentInlineStyle().has(style);
  }

  const selectionStart = selection.isCollapsed() ? Math.max(selection.getStartOffset() - 1, 0) : selection.getStartOffset();
  const selectionEnd = selection.getEndOffset();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const blocks = getSelectedBlocks(selection, editorState.getCurrentContent());

  return blocks.every((block) => {

    if (!block) {
      return false;
    }

    const blockStartCharacter = block.getKey() === startKey ? selectionStart : 0;
    const blockEndCharacter = block.getKey() === endKey ? selectionEnd : block.getLength();
    const characterList = block.getCharacterList().slice(blockStartCharacter, blockEndCharacter);

    return characterList.size !== 0 && characterList.every((char) => { return (char?.hasStyle(style) || false); });
  });
}

export function toggleScriptStyle(editorState: EditorState, style: Constants.ScriptType): EditorState {
  let resultEditorState = editorState;

  if (editorState.getSelection().isCollapsed()) {
    _.values(Constants.ScriptType).forEach((scriptStyle) => {
      const hasScriptStyle = editorState.getCurrentInlineStyle().has(scriptStyle);

      if (hasScriptStyle && style !== scriptStyle) {
        resultEditorState = RichUtils.toggleInlineStyle(resultEditorState, scriptStyle);
      }
    });
  } else if (!isStyleAppliedToAllSelection(editorState, style)) {
    resultEditorState = removeSpecifiedInlineStylesForSelection(editorState, Constants.SCRIPT_CONTROLS.map(scriptStyle => scriptStyle.style));
  }

  return RichUtils.toggleInlineStyle(resultEditorState, style);
}

export function setBlockAnchorValue(editorState: EditorState, blockKey: string, anchorValue: boolean): EditorState {
  return EditorState.set(editorState, {
    currentContent: editorState.getCurrentContent().update(
      'blockMap',
      (blockMap: Draft.BlockMap) => blockMap.setIn([blockKey, 'data', Constants.BlockDataKey.ANCHOR], anchorValue),
    ),
  });
}

export function getAppliedAnchorKeys(editorState: EditorState): string[] {
  return editorState.getCurrentContent().getBlockMap().reduce(
    (appliedAnchorKeys: string[], block) => {

      if (block && appliedAnchorKeys) {
        const anchor: boolean = block.getData().get(Constants.BlockDataKey.ANCHOR);

        if (anchor) {
          const key = block.getKey();
          appliedAnchorKeys.push(key);
        }
      }

      return appliedAnchorKeys;
    },
    [] as string[],
  );
}

function updateBlocks(
  editorState: EditorState, updater: (block?: Draft.ContentBlock, index?: string) => Draft.ContentBlock | undefined,
): EditorState {
  return EditorState.set(editorState, {
    currentContent: editorState.getCurrentContent().update(
      'blockMap',
      (blockMap: Draft.BlockMap) => blockMap.map(updater),
    ),
  });
}

export function applyBulletColor(editorState: EditorState, bulletColor: string): EditorState {
  let newEditorState = removeInlineStylesWithPrefix(editorState, Constants.StylePrefix.BULLET_COLOR);

  newEditorState = updateBlocks(newEditorState, (block) => {
    if (block.getType() !== Constants.TextHorizontalAlignmentType.UNORDERED_LIST) {
      return block;
    }

    const updatedCharacterList = block.getCharacterList().map((character) => {
      return CharacterMetadata.applyStyle(character, bulletColor);
    });

    return block.set('characterList', updatedCharacterList) as ContentBlock;
  });

  return EditorState.push(newEditorState, newEditorState.getCurrentContent(), 'change-inline-style');
}

export function applyAlignment(editorState: EditorState, alignment: Record<string, Constants.TextHorizontalAlignmentType>): EditorState {
  return updateBlocks(editorState, (block) => {
    return block ? block.set('type', alignment[block.get('key')]) as Draft.ContentBlock : undefined;
  });
}

export function applyDefaultAlignment(editorState: EditorState): EditorState {
  return updateBlocks(editorState, (block) => {
    return block ? block.set('type', Constants.DefaultTextAlignment) as Draft.ContentBlock : undefined;
  });
}

export function applyLineHeight(editorState: EditorState, lineHeight: Models.TextLineHeight): EditorState {
  return updateBlocks(editorState, (block) => {
    if (!block) {
      return undefined;
    }

    const newBlockData = block.getData().set(Constants.BlockDataKey.LINE_HEIGHT, lineHeight[block.get('key')]);

    return block.set('data', newBlockData) as Draft.ContentBlock;
  });
}

export function applyDefaultLineHeight(editorState: EditorState): EditorState {
  return updateBlocks(
    editorState,
    block => block.set('data', block.getData().set(Constants.BlockDataKey.LINE_HEIGHT, Constants.DefaultLineHeight),
    ) as Draft.ContentBlock);
}

export function applyInlineStyleRanges(editorState: EditorState, inlineStyles: Models.StyleRanges = []): EditorState {
  let contentState = editorState.getCurrentContent();

  inlineStyles.forEach(({ offset, length, style, blockKey }) => {
    const block = contentState.getBlockForKey(blockKey);
    if (!block) {
      return;
    }
    const blockLength = block.getLength();
    const selectionState = editorState.getSelection().merge({
      focusKey: blockKey,
      anchorKey: blockKey,
      anchorOffset: offset,
      focusOffset: Math.min(offset + length, blockLength), // to make sure that style range isn't more than length of its block
    }) as SelectionState;

    if (offset < blockLength) {
      contentState = Modifier.applyInlineStyle(contentState, selectionState, style);
    }
  });

  return EditorState.set(editorState, { currentContent: contentState });
}

export function applyInlineStyleToAllState(editorState: EditorState, style: string): EditorState {
  let contentState = editorState.getCurrentContent();
  const selectionState = setFullSelection(editorState).getSelection();
  contentState = Modifier.applyInlineStyle(contentState, selectionState, style);

  return EditorState.set(editorState, { currentContent: contentState });
}

function getBrandFont(styles: Draft.DraftInlineStyle, fonts: Models.BrandFontsList): Models.BrandFontMap | string | undefined {
  const fontFamilyStyle = inlineStylesUtils.findFontFamilyStyle(styles);
  const fontFamily = fontFamilyStyle && inlineStylesUtils.getFontFamilyFromStyle(fontFamilyStyle);

  return fontFamily && fonts && fonts.find(font => font.get('name') === fontFamily);
}

export function checkStylesForBoldOrItalic(styles: Draft.DraftInlineStyle): { isBold: boolean; isItalic: boolean } {
  let isBold = styles.includes(Constants.OldInlineStyle.BOLD);
  const fontWeightStyle = inlineStylesUtils.findFontWeightStyle(styles);
  if (fontWeightStyle) {
    const fontWeight = Number(inlineStylesUtils.getFontWeightFromStyle(fontWeightStyle));
    const fontWeightForBold = Number(inlineStylesUtils.getFontWeightFromStyle(Constants.InlineStylesForToggling.BOLD));
    isBold = fontWeight >= fontWeightForBold;
  }

  const isItalic = styles.includes(Constants.OldInlineStyle.ITALIC) || styles.includes(Constants.InlineStyle.ITALIC);

  return {
    isBold,
    isItalic,
  };
}

function getCharacterWithAppliedStyles(
  character: Draft.CharacterMetadata,
  characterStyleToApply: DeepIMap<Models.CharacterStyle>,
  needToApplyFakeItalic: boolean,
): Draft.CharacterMetadata {
  const styles = character.getStyle();
  const { isBold, isItalic } = checkStylesForBoldOrItalic(styles);
  const prevCharacterStyleNameStyle = inlineStylesUtils.findCharacterStyleNameStyle(styles);
  const prevCharacterStyleFontStyleStyle = inlineStylesUtils.findFontStyleStyle(styles);
  const prevCharacterStyleFontWeightStyle = inlineStylesUtils.findFontWeightStyle(styles);
  const nextCharacterStyleNameStyle = characterStyleToApply && inlineStylesUtils.getCharacterStyleNameStyle(characterStyleToApply.get('name'));

  let nextCharacterStyleFontStyleStyle = characterStyleToApply
    ? inlineStylesUtils.getFontStyleStyle(characterStyleToApply.get('fontStyle'))
    : isItalic
      ? Constants.InlineStylesForToggling.ITALIC
      : Constants.InlineStylesForToggling.NORMAL;

  if (needToApplyFakeItalic) {
    nextCharacterStyleFontStyleStyle = Constants.InlineStylesForToggling.ITALIC;
  }

  const nextCharacterStyleFontWeightStyle = characterStyleToApply
    ? inlineStylesUtils.getFontWeightStyle(characterStyleToApply.get('fontWeight') ?? '')
    : isBold
      ? Constants.InlineStylesForToggling.BOLD
      : Constants.InlineStylesForToggling.REGULAR;

  // we have to explicitly remove 'BOLD' and/or 'ITALIC' from Character Metadata
  // because from this point we will use only _font_weight_100...950 and _font_style_normal/italic
  let updatedCharacter = styles.includes(Constants.OldInlineStyle.BOLD)
    ? CharacterMetadata.removeStyle(character, Constants.OldInlineStyle.BOLD)
    : character;

  updatedCharacter = styles.includes(Constants.OldInlineStyle.ITALIC)
    ? CharacterMetadata.removeStyle(updatedCharacter, Constants.OldInlineStyle.ITALIC)
    : updatedCharacter;

  updatedCharacter = prevCharacterStyleNameStyle
    ? CharacterMetadata.removeStyle(updatedCharacter, prevCharacterStyleNameStyle)
    : updatedCharacter;

  updatedCharacter = prevCharacterStyleFontStyleStyle
    ? CharacterMetadata.removeStyle(updatedCharacter, prevCharacterStyleFontStyleStyle)
    : updatedCharacter;

  updatedCharacter = prevCharacterStyleFontWeightStyle
    ? CharacterMetadata.removeStyle(updatedCharacter, prevCharacterStyleFontWeightStyle)
    : updatedCharacter;
  // TODO: sometimes we have '_character_style_name_undefined'.
  //  Need to find out why and remove "!nextCharacterStyleNameStyle.includes('undefined')" condition
  updatedCharacter = nextCharacterStyleNameStyle && !nextCharacterStyleNameStyle.includes('undefined')
    ? CharacterMetadata.applyStyle(updatedCharacter, nextCharacterStyleNameStyle)
    : updatedCharacter;

  updatedCharacter = nextCharacterStyleFontStyleStyle
    ? CharacterMetadata.applyStyle(updatedCharacter, nextCharacterStyleFontStyleStyle)
    : updatedCharacter;

  updatedCharacter = nextCharacterStyleFontWeightStyle
    ? CharacterMetadata.applyStyle(updatedCharacter, nextCharacterStyleFontWeightStyle)
    : updatedCharacter;

  return updatedCharacter;
}

export const applyFontStylesForBrandStyles = (
  editorState,
  fonts: Models.BrandFontsList,
): Draft.EditorState => {
  return updateBlocks(editorState, (block) => {
    const updatedCharacterList = block.getCharacterList().map((character) => {
      const styles = character.getStyle();
      let characterStyleToApply;
      const currentCharacterStyle = inlineStylesUtils.findCharacterStyleNameStyle(styles);
      const currentCharacterStyleName = currentCharacterStyle && inlineStylesUtils.getCharacterStyleNameFromStyle(currentCharacterStyle);
      const brandFont = getBrandFont(styles, fonts);
      const isFontStylePresent = styles.includes(Constants.InlineStylesForToggling.NORMAL) || styles.includes(Constants.InlineStyle.ITALIC);
      const isFontWeightPresent = !!inlineStylesUtils.findFontWeightStyle(styles);
      const characterStyles = brandFont && brandFont.get('characterStyles');
      if (characterStyles && characterStyles.size > 0) {
        const { isBold, isItalic } = checkStylesForBoldOrItalic(styles);

        const fontStyle = (isBold && isItalic)
          ? Constants.FontStyle.ITALIC_STRONG
          : isBold
            ? Constants.FontStyle.STRONG
            : isItalic
              ? Constants.FontStyle.ITALIC
              : Constants.FontStyle.REGULAR;

        characterStyleToApply = characterStyles.find(charStyle => charStyle.get('if') === fontStyle);
      }
      const isSameCharacterStyle = characterStyleToApply && characterStyleToApply.get('name') === currentCharacterStyleName;

      // TODO: sometimes we have no '_font_style_...' / '_font_weight_...' styles.
      //  Need to figure out why and maybe remove isFontStylePresent && isFontWeightPresent flags
      if (isFontStylePresent && isFontWeightPresent && (!characterStyleToApply || isSameCharacterStyle)) {
        return character;
      }

      return getCharacterWithAppliedStyles(character, characterStyleToApply, false);
    });

    return block.set('characterList', updatedCharacterList) as ContentBlock;
  });
};
// TODO: remove this function when all brand definition files will have bold presets for every custom font
const getSubstituteCharacterStyle = (
  characterStyles: Models.CharacterStylesList,
  fontStyle: string,
  fontWeightToFindClosestTo: string,
): DeepIMap<Models.CharacterStyle> => {
  const fontWeightForRegular = Number(Constants.InlineFontStyleNoPrefix.REGULAR);
  const fontWeightForBold = Number(Constants.InlineFontStyleNoPrefix.BOLD);
  const characterStylesWithCurrentFontStyle = characterStyles.filter((charStyle) => {
    const hasMatchingFontStyle = charStyle.get('fontStyle') === fontStyle;
    let isMatchingCharacterStyle = false;

    if (hasMatchingFontStyle) {
      isMatchingCharacterStyle = Number(fontWeightToFindClosestTo) < fontWeightForBold
        ? Number(charStyle.get('fontWeight')) < fontWeightForBold
        : Number(charStyle.get('fontWeight')) >= fontWeightForBold;
    }

    return isMatchingCharacterStyle;
  });

  return characterStylesWithCurrentFontStyle
    && characterStylesWithCurrentFontStyle.reduce((prev: Models.CharacterStyleMap, curr: Models.CharacterStyleMap) => {
      const fontWeightToUseForDifference = Number(fontWeightToFindClosestTo) < fontWeightForBold
        ? fontWeightForRegular
        : fontWeightForBold;

      const currDiff = Math.abs(Number(curr.get('fontWeight')) - fontWeightToUseForDifference);
      const prevDiff = Math.abs(Number(prev.get('fontWeight')) - fontWeightToUseForDifference);

      return currDiff < prevDiff ? curr : prev;
    });
};

/**
 * Try to use available (ALL, CONDENSED, EXTENDED) CharacterStyle lists to search for matching CharacterStyles.
 * If we have a special (Condensed, Extended) CharacterStyle then we try to use only matching subset of CharacterStyles.
 * @param characterStyles List of CharacterStyles to search for CharacterStyle to apply
 * @param characterStyleName CharacterStyle name to search for CharacterStyle to apply
 * @param fontStyle fontStyle to find a match for
 * @param fontWeight fontWeight to find a match for
 * @param isItalic if current CharacterMetadata contains Italic styling
 * @param isBold if current CharacterMetadata contains Bold styling
 */
const tryToFindCharacterStyleInAvailableLists = (
  characterStyles: Models.CharacterStylesList,
  characterStyleName: string,
  fontStyle: string,
  fontWeight: string,
  isItalic: boolean,
  isBold: boolean,
): Models.CharacterStyleMap => {
  let characterStyleToApply;
  const isCondensed = characterStyleName
    && characterStyleName.toLowerCase().includes(Constants.FontFamily.CONDENSED.toLowerCase());
  const isExtended = characterStyleName
    && characterStyleName.toLowerCase().includes(Constants.FontFamily.EXTENDED.toLowerCase());

  if (isCondensed) {
    const condensedCharacterStyles = characterStyles
      .filter(charStyle => charStyle.get('name').toLowerCase().includes(Constants.FontFamily.CONDENSED.toLowerCase()));
    characterStyleToApply = tryToFindCharacterStyle(condensedCharacterStyles, fontStyle, fontWeight, isItalic, isBold);
  }

  if (isExtended) {
    const extendedCharacterStyles = characterStyles
      .filter(charStyle => charStyle.get('name').toLowerCase().includes(Constants.FontFamily.EXTENDED.toLowerCase()));
    characterStyleToApply = tryToFindCharacterStyle(extendedCharacterStyles, fontStyle, fontWeight, isItalic, isBold);
  }

  if (!characterStyleToApply) {
    characterStyleToApply = tryToFindCharacterStyle(characterStyles, fontStyle, fontWeight, isItalic, isBold);
  }

  return characterStyleToApply;
};

/**
 * Try to find 100% matching character style. If no such character style then try to find the closest available.
 * @param characterStyles List of CharacterStyles to search for CharacterStyle to apply
 * @param fontStyle fontStyle to find a match for
 * @param fontWeight fontWeight to find a match for
 * @param isItalic if current CharacterMetadata contains Italic styling
 * @param isBold if current CharacterMetadata contains Bold styling
 */
const tryToFindCharacterStyle = (
  characterStyles: Models.CharacterStylesList,
  fontStyle: string,
  fontWeight: string,
  isItalic: boolean,
  isBold: boolean,
): Models.CharacterStyleMap => {
  let characterStyleToApply = characterStyles && characterStyles
    .find(charStyle => charStyle.get('fontStyle') === fontStyle && charStyle.get('fontWeight') === fontWeight);

  if (!characterStyleToApply && isItalic) {
    characterStyleToApply = characterStyles && characterStyles
      .find(charStyle => charStyle.get('fontWeight') === fontWeight);
  }

  const isRegularSubstituteCharStyleNeeded = (!isBold && !characterStyleToApply)
    || (!isBold && characterStyleToApply
      && Number(characterStyleToApply.get('fontWeight')) !== Number(Constants.InlineFontStyleNoPrefix.REGULAR));

  const isBoldSubstituteCharStyleNeeded = (isBold && !characterStyleToApply)
    || (isBold && characterStyleToApply
      && Number(characterStyleToApply.get('fontWeight')) <= Number(Constants.InlineFontStyleNoPrefix.BOLD));

  if (!characterStyleToApply && (isRegularSubstituteCharStyleNeeded || isBoldSubstituteCharStyleNeeded)) {
    const fontStyle = isItalic ? Constants.InlineFontStyleNoPrefix.ITALIC : Constants.InlineFontStyleNoPrefix.NORMAL;
    const fontWeightToFindClosestTo = isRegularSubstituteCharStyleNeeded && Constants.InlineFontStyleNoPrefix.REGULAR
      || isBoldSubstituteCharStyleNeeded && Constants.InlineFontStyleNoPrefix.BOLD;
    characterStyleToApply = characterStyles
      && getSubstituteCharacterStyle(characterStyles, fontStyle, fontWeightToFindClosestTo);
    if (!characterStyleToApply) {
      characterStyleToApply = characterStyles
        && getSubstituteCharacterStyle(characterStyles, Constants.InlineFontStyleNoPrefix.NORMAL, fontWeightToFindClosestTo);
    }
  }

  return characterStyleToApply;
};

export const applyFontStyles = (
  editorState,
  fonts: Models.BrandFontsList,
  projectType: string,
): Draft.EditorState => {
  return updateBlocks(editorState, (block) => {
    const updatedCharacterList = block.getCharacterList().map((character) => {
      const styles = character.getStyle();
      let characterStyleToApply;
      const brandFont = getBrandFont(styles, fonts);
      const { applyFontStyleForBrandStyles } = Constants.ProjectsConfig[projectType];
      const isSystemFont = !!brandFont && !!brandFont.get('fontColor');
      if (applyFontStyleForBrandStyles && isSystemFont) {
        return character;
      }
      const characterStyles = brandFont && brandFont.get('characterStyles');
      const { isBold, isItalic } = checkStylesForBoldOrItalic(styles);
      const fontStyleStyle = inlineStylesUtils.findFontStyleStyle(styles);
      const fontWeightStyle = inlineStylesUtils.findFontWeightStyle(styles);
      // isNewConvention means that we have updated "_font_style_normal/italic" present in styles
      // it's needed to distinguish these cases from "_font_style_Regular/Light" and so on
      const isNewConvention = fontStyleStyle === Constants.InlineStylesForToggling.NORMAL
        || fontStyleStyle === Constants.InlineStylesForToggling.ITALIC;
      const areCharacterStylesPresent = !!characterStyles && characterStyles.size > 0;

      if (areCharacterStylesPresent) {
        if (isNewConvention) {
          const fontStyle = fontStyleStyle && inlineStylesUtils.getFontStyleFromStyle(fontStyleStyle);
          const fontWeight = fontWeightStyle && inlineStylesUtils.getFontWeightFromStyle(fontWeightStyle);
          const characterStyleNameStyle = inlineStylesUtils.findCharacterStyleNameStyle(styles);
          const characterStyleName = characterStyleNameStyle && inlineStylesUtils.getCharacterStyleNameFromStyle(characterStyleNameStyle);
          characterStyleToApply = characterStyles
            && characterStyles.find(charStyle => charStyle.get('name') === characterStyleName);

          const fontStyleFromCharStyle = characterStyleToApply && characterStyleToApply.get('fontStyle');
          const fontWeightFromCharStyle = characterStyleToApply && characterStyleToApply.get('fontWeight');

          if (fontStyle !== fontStyleFromCharStyle || fontWeight !== fontWeightFromCharStyle) {
            characterStyleToApply = tryToFindCharacterStyleInAvailableLists(
              characterStyles,
              characterStyleName,
              fontStyle,
              fontWeight,
              isItalic,
              isBold,
            );
          } else {
            return character;
          }
        } else {
          let fontStyle;
          let fontWeight;
          // we have to do this HACK because in Old Convention fontStyle was not about "normal/italic" values
          // it could have a value of "Light" or "Regular" for example and this is what is called "characterStyleName" in New Convention
          const characterStyleName = fontStyleStyle && inlineStylesUtils.getFontStyleFromStyle(fontStyleStyle);
          characterStyleToApply = characterStyles
            && characterStyles.find(charStyle => charStyle.get('name') === characterStyleName);

          const fontStyleFromCharStyle = characterStyleToApply && characterStyleToApply.get('fontStyle');
          const fontWeightFromCharStyle = characterStyleToApply && characterStyleToApply.get('fontWeight');

          if (isItalic) {
            fontStyle = fontStyleFromCharStyle === Constants.InlineFontStyleNoPrefix.ITALIC
              ? fontStyleFromCharStyle
              : Constants.InlineFontStyleNoPrefix.ITALIC;
          } else {
            fontStyle = fontStyleFromCharStyle;
          }

          if (isBold) {
            fontWeight = Number(fontWeightFromCharStyle) >= Number(Constants.InlineFontStyleNoPrefix.BOLD)
              ? fontWeightFromCharStyle
              : Constants.InlineFontStyleNoPrefix.BOLD;
          } else {
            fontWeight = fontWeightFromCharStyle;
          }

          if (fontStyle !== fontStyleFromCharStyle || fontWeight !== fontWeightFromCharStyle) {
            characterStyleToApply = tryToFindCharacterStyleInAvailableLists(
              characterStyles,
              characterStyleName,
              fontStyle,
              fontWeight,
              isItalic,
              isBold,
            );
          }
        }
      } else if (isNewConvention) {
        return character;
      }

      const needToApplyFakeItalic = (isItalic && !characterStyleToApply)
        || (isItalic && characterStyleToApply && characterStyleToApply.get('fontStyle') !== Constants.InlineFontStyleNoPrefix.ITALIC);

      return getCharacterWithAppliedStyles(character, characterStyleToApply, needToApplyFakeItalic);
    });

    return block.set('characterList', updatedCharacterList) as ContentBlock;
  });
};

/**
 * Remove all styles with prefix from entire editorState
 * @param editorState Draft.EditorState
 * @param stylePrefix StylePrefix
 */
function removeInlineStylesWithPrefix(editorState: EditorState, stylePrefix: Constants.StylePrefix): EditorState {
  let resultEditorState = setFullSelection(editorState);
  const contentState = getResetStateForMultiOptionStyle(resultEditorState, stylePrefix);
  resultEditorState = EditorState.set(resultEditorState, { currentContent: contentState });

  return resultEditorState;
}

export function removeAllInlineStylesWithPrefix(editorState: EditorState): EditorState {
  const stylesToIgnore = [
    Constants.StylePrefix.FONT_STYLE,
    Constants.StylePrefix.FONT_WEIGHT,
    Constants.StylePrefix.FONT_COLOR,
    Constants.StylePrefix.FONT_COLOR_TINT,
    Constants.StylePrefix.FONT_COLOR_NAME,
    Constants.StylePrefix.BULLET_COLOR,
    Constants.StylePrefix.BULLET_COLOR_TINT,
    Constants.StylePrefix.BULLET_COLOR_NAME,
  ];

  return _(Constants.StylePrefix)
    .values()
    // omit _font_color_, font_style and font_weight to mimic/support native BOLD and ITALIC draftjs styles
    .filter(prefix => !stylesToIgnore.includes(prefix))
    .value()
    .reduce(
      (resultEditorState, stylePrefix: Constants.StylePrefix) => removeInlineStylesWithPrefix(resultEditorState, stylePrefix),
      editorState,
    );
}

/**
 * Extract inline styles with prefix from editorState and return them
 * @param editorState Draft.EditorState
 * @param stylePrefix StylePrefix
 */
function extractInlineStylesWithPrefix(editorState: Draft.EditorState, stylePrefix: Constants.StylePrefix): Models.StyleRanges {
  return editorState.getCurrentContent().getBlockMap().reduce(
    (styles, block) => {
      block.findStyleRanges(
        value => value.getStyle().some(style => style.startsWith(stylePrefix)),
        (start, end) => {
          const styleName = inlineStylesUtils.findStyle(block.getInlineStyleAt(start), stylePrefix);
          styles.push({ style: styleName, offset: start, length: end - start, blockKey: block.getKey() });
        },
      );

      return styles;
    },
    [] as Models.StyleRanges,
  );
}

/**
 * Remove specific inline styles from entire editorState and return them
 * @param editorState Draft.EditorState
 */
export function isolateInlineStyles(
  editorState: Draft.EditorState,
): {
    editorState: Draft.EditorState;
    bulletColor: Models.StyleRanges;
    fontColor: Models.StyleRanges;
    fontSize: Models.StyleRanges;
    fontFamily: Models.StyleRanges;
    fontStyle: Models.StyleRanges;
    fontWeight: Models.StyleRanges;
    characterStyleName: Models.StyleRanges;
  } {
  const bulletColor = extractInlineStylesWithPrefix(editorState, Constants.StylePrefix.BULLET_COLOR);
  const fontColor = extractInlineStylesWithPrefix(editorState, Constants.StylePrefix.FONT_COLOR);
  const fontSize = extractInlineStylesWithPrefix(editorState, Constants.StylePrefix.FONT_SIZE);
  const fontFamily = extractInlineStylesWithPrefix(editorState, Constants.StylePrefix.FONT_FAMILY);
  const fontStyle = extractInlineStylesWithPrefix(editorState, Constants.StylePrefix.FONT_STYLE);
  const fontWeight = extractInlineStylesWithPrefix(editorState, Constants.StylePrefix.FONT_WEIGHT);
  const characterStyleName = extractInlineStylesWithPrefix(editorState, Constants.StylePrefix.CHARACTER_STYLE_NAME);

  return {
    bulletColor,
    fontColor,
    fontSize,
    fontFamily,
    fontStyle,
    fontWeight,
    characterStyleName,
    editorState: removeAllInlineStylesWithPrefix(editorState),
  };
}

export function getStyleToDisplay(
  editorState: Draft.EditorState,
  stylePrefix: Constants.StylePrefix,
  defaultStyle?: Constants.DefaultCustomStyle,
  multiStyle?: Constants.MultiCustomStyle,
): string | undefined {
  let currentStyle: DraftInlineStyle;

  // No multi selected characters
  if (editorState.getSelection().isCollapsed()) {
    currentStyle = editorState.getCurrentInlineStyle().filter(style => !!style?.startsWith(stylePrefix)).toOrderedSet();
    // Multi character selected
  } else {
    currentStyle = getAllStylesWithPrefixInCurrentSelection(editorState, stylePrefix);
  }

  if (currentStyle.size > 1) {
    return multiStyle;
  } else if (currentStyle.size === 1) {
    return inlineStylesUtils.getValueFromStyle(currentStyle.first(), stylePrefix);
  } else if (defaultStyle) {
    return String(defaultStyle);
  }

  return undefined;
}

export function getActiveFontFamily(editorState: Draft.EditorState): Models.FontFamily {
  const fontFamily = getStyleToDisplay(editorState, Constants.StylePrefix.FONT_FAMILY, Constants.DefaultCustomStyle.FONT_FAMILY, null);
  const characterStyleName = getStyleToDisplay(editorState, Constants.StylePrefix.CHARACTER_STYLE_NAME, null, null);

  return {
    fontFamily,
    characterStyleName,
  };
}

export function getCurrentFontSize(editorState: Draft.EditorState): number {
  const fontSizeStyle = getStyleToDisplay(
    editorState,
    Constants.StylePrefix.FONT_SIZE,
    Constants.DefaultCustomStyle.FONT_SIZE,
    null,
  );

  return fontSizeStyle && getIntegerFromStyle(fontSizeStyle);
}

export function applyDefaultStyles(editorState: EditorState, defaultStyles?: Models.TextInlineStyles): EditorState {
  const {
    characterStyle,
    lineHeight,
    textAlign,
    fontColor,
    fontSize,
    fontFamily,
    bulletColor,
  } = _(defaultStyles).omitBy(_.isNull).defaults(DefaultTextStyles).value();

  return updateBlocks(editorState, (block) => {
    let updatedBlock = block;
    const blockData = block.getData();
    const blockType = block.getType();

    if (!blockData.has(Constants.BlockDataKey.LINE_HEIGHT)) {
      updatedBlock = updatedBlock.set('data', blockData.set(Constants.BlockDataKey.LINE_HEIGHT, lineHeight)) as ContentBlock;
    }

    if (!blockType || blockType === Constants.UNSTYLED_BLOCK_TYPE) {
      updatedBlock = updatedBlock.set('type', textAlign) as ContentBlock;
    }

    const updatedCharacterList = block.getCharacterList().map((character) => {
      let updatedStyle = character.getStyle();
      const bulletColorStyle = inlineStylesUtils.findBulletColorStyle(updatedStyle);
      const fontColorStyle = inlineStylesUtils.findFontColorStyle(updatedStyle);
      const fontSizeStyle = inlineStylesUtils.findFontSizeStyle(updatedStyle);
      const fontFamilyStyle = inlineStylesUtils.findFontFamilyStyle(updatedStyle);
      const fontStyleStyle = inlineStylesUtils.findFontStyleStyle(updatedStyle);
      const fontWeightStyle = inlineStylesUtils.findFontWeightStyle(updatedStyle);
      const characterStyleNameStyle = inlineStylesUtils.findCharacterStyleNameStyle(updatedStyle);

      if (!bulletColorStyle && blockType === Constants.TextHorizontalAlignmentType.UNORDERED_LIST) {
        updatedStyle = updatedStyle.add(inlineStylesUtils.getBulletColorStyle(bulletColor));
      }

      if (!fontColorStyle) {
        updatedStyle = updatedStyle.add(inlineStylesUtils.getFontColorStyle(fontColor));
      }

      if (!fontSizeStyle) {
        updatedStyle = updatedStyle.add(inlineStylesUtils.getFontSizeStyle(fontSize));
      }

      if (!fontFamilyStyle) {
        updatedStyle = updatedStyle.add(inlineStylesUtils.getFontFamilyStyle(fontFamily));
      }

      if (!fontStyleStyle) {
        updatedStyle = updatedStyle.add(inlineStylesUtils.getFontStyleStyle(characterStyle));
      }

      if (!fontWeightStyle) {
        updatedStyle = updatedStyle.add(inlineStylesUtils.getFontWeightStyle(characterStyle));
      }

      if (!characterStyleNameStyle) {
        updatedStyle = updatedStyle.add(inlineStylesUtils.getCharacterStyleNameStyle(characterStyle));
      }

      return CharacterMetadata.create({
        entity: character.getEntity(),
        style: updatedStyle,
      });
    });

    return updatedBlock.set('characterList', updatedCharacterList) as ContentBlock;
  });
}

function getColorFromControlStyle(colorStyleValue: string, colors: Models.BrandColorsList): string {
  let color = Constants.DefaultCustomStyle.FONT_COLOR as string;

  if (colorStyleValue.startsWith(Constants.HEX_COLOR_START_KEY)) {
    color = colorStyleValue;
  } else {
    const brandColor = colors.find(color => color.get('name') === colorStyleValue);

    if (brandColor) {
      color = brandColor.get('HEX');
    }
  }

  return color;
}

export const getBulletColorFromFontColorStyle = (
  bulletColorStyle: string,
  colors: Models.BrandColorsList,
): string => {
  const bulletColorStyleValue = inlineStylesUtils.getBulletColorFromStyle(bulletColorStyle);

  return getColorFromControlStyle(bulletColorStyleValue, colors);
};

interface FontFamilyWithFallbackFonts {
  fontFamily: string;
  fallbackFonts: string;
}

type GetFontFamilyStylesResult<T> = T extends true ? string : FontFamilyWithFallbackFonts;

function fontFamilyWithFallbackFontsToString(fontFamilyWithFallbackFonts: FontFamilyWithFallbackFonts): string {
  const { fontFamily, fallbackFonts } = fontFamilyWithFallbackFonts;
  const wrappedFontFamily = getFontFaceStringWrapper(fontFamily);

  return fallbackFonts ? wrappedFontFamily.concat(', ', fallbackFonts) : wrappedFontFamily;
}

export function getFontFamilyFromRegularStyles<T extends boolean = true>(
  fontFamily: string,
  characterStyleName: string,
  fonts: Models.BrandFontsList,
  options?: { returnAsString: T },
  isExplicitFontFamilyTransformationNeeded = false,
): GetFontFamilyStylesResult<T> {
  const { returnAsString } = _.defaults(options, { returnAsString: true });

  let fallbackFonts: string;
  let newFontFamily = Constants.DefaultCustomStyle.FONT_FAMILY as string;
  const brandFont = fonts && fonts.find(font => font.get('name') === fontFamily);

  if (brandFont) {
    newFontFamily = brandFont.get('fontFamily');
    fallbackFonts = brandFont.get('fallbackFonts');
  }

  if (characterStyleName) {
    const characterStyles = brandFont && brandFont.get('characterStyles');
    const characterStyle = characterStyles
      && characterStyles.find(charStyle => charStyle.get('name') === characterStyleName);
    const isCharacterStyleOfSystemFont = brandFont && !!brandFont.get('fontColor');
    const fontFileName = characterStyle && characterStyle.get('fontFileName');
    if (fontFileName) {
      const name = brandFont && brandFont.get('name');
      const fontAlias = characterStyle && characterStyle.get('if');
      const isFontAliasInSystemFont = isCharacterStyleOfSystemFont && !!fontAlias;
      newFontFamily = transformFontFamily(newFontFamily, fontFileName, name, isFontAliasInSystemFont);
    } // we have to do this explicit font family transformation when we need it but we do not have a character style yet
  } else if (!characterStyleName && isExplicitFontFamilyTransformationNeeded) {
    newFontFamily = transformFontFamily(newFontFamily);
  }

  const fontFamilyWithFallbackFonts: FontFamilyWithFallbackFonts = {
    fontFamily: newFontFamily,
    fallbackFonts,
  };

  return (
    returnAsString
      ? fontFamilyWithFallbackFontsToString(fontFamilyWithFallbackFonts)
      : fontFamilyWithFallbackFonts
  ) as GetFontFamilyStylesResult<T>;
}

/**
 * Returns transformed fontFamily if the variation of the font is condensed, ultra condensed or extended
 * which is needed by browser to correctly select font
 * @param fontFamily - fontFamily value of the current font to be transformed
 * @param fontFileName - fontFileName value of the current font to be analyzed
 * @param fontName - name value of the current font to be used to make transformed fontFamily
 * @param isFontAliasInSystemFont - isFontAliasInSystemFont value of the current font to be used to make decision to transform fontFamily
 */
function transformFontFamily(
  fontFamily: string,
  fontFileName?: string,
  fontName?: string,
  isFontAliasInSystemFont?: boolean,
): string {
  if (isFontAliasInSystemFont) {
    return `${fontFamily}-${fontName}`;
  }

  const stringToCheckForSpecialStyles = fontFileName ? fontFileName : fontFamily;

  for (const value of Object.values(Constants.FontFamily)) {
    if (stringToCheckForSpecialStyles.toLowerCase().includes(value.toLowerCase().replace(/\s/g, ''))) {
      return `${fontFamily} ${value}`;
    }
  }

  return fontFamily;
}

export function getFontFamilyFromInlineStyles<T extends boolean = true>(
  fontFamilyInlineStyle: string,
  characterStyleNameStyle: string,
  fonts: Models.BrandFontsList,
  options?: { returnAsString: T },
  isExplicitFontFamilyTransformationNeeded = false,
): GetFontFamilyStylesResult<T> {
  const fontFamily = fontFamilyInlineStyle && inlineStylesUtils.getFontFamilyFromStyle(fontFamilyInlineStyle);
  const characterStyleName = characterStyleNameStyle && inlineStylesUtils.getCharacterStyleNameFromStyle(characterStyleNameStyle);

  return getFontFamilyFromRegularStyles(fontFamily, characterStyleName, fonts, options, isExplicitFontFamilyTransformationNeeded);
}

export function checkFontColorStyles(styles: any) {
  return Boolean(styles.find(el => el?.includes('_font_color')));
}

export const getCustomInlineStyle = (
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
  editorState?: MutableRefObject<EditorState>,
) => (styles: Draft.DraftInlineStyle): Models.CSSInlineStyle => {
  let resultStyle: Models.CSSInlineStyle = {
    ...getFontSize(styles),
  };

  let cellStyles;
  let characterStyles;
  let characterStyle;

  const isHaveColorStyles = checkFontColorStyles(styles);
  const stylesBlock = editorState?.current?.getCurrentContent().getBlocksAsArray() || [];

  for (const block of stylesBlock) {
    const blockStyles = block.getInlineStyleAt(0).toArray();
    const isHasFontColor = checkFontColorStyles(blockStyles);
    if (isHasFontColor) {
      cellStyles = block.getInlineStyleAt(0);
    }
  }

  const styleOption = !isHaveColorStyles && cellStyles ? cellStyles : styles;
  const fontFamilyStyle = inlineStylesUtils.findFontFamilyStyle(styleOption);
  const fontStyleStyle = inlineStylesUtils.findFontStyleStyle(styleOption);
  const fontWeightStyle = inlineStylesUtils.findFontWeightStyle(styleOption);
  const characterStyleNameStyle = inlineStylesUtils.findCharacterStyleNameStyle(styles);
  const fontColorStyle = inlineStylesUtils.findFontColorStyle(styleOption);
  const bulletColorStyle = inlineStylesUtils.findBulletColorStyle(styles);
  const hasSuperScriptStyle = styles.includes(Constants.ScriptType.SUPERSCRIPT);
  const hasSubScriptStyle = styles.includes(Constants.ScriptType.SUBSCRIPT);
  let needToApplyArtificialItalic = false;

  if (fontFamilyStyle) {
    resultStyle.fontFamily = getFontFamilyFromInlineStyles(fontFamilyStyle, characterStyleNameStyle, fonts);
  }

  if (characterStyleNameStyle) {
    const characterStyleName = inlineStylesUtils.getCharacterStyleNameFromStyle(characterStyleNameStyle);
    const fontFamily = fontFamilyStyle && inlineStylesUtils.getFontFamilyFromStyle(fontFamilyStyle);
    const brandFont = fonts && fontFamily && fonts.find(item => item.get('name') === fontFamily);
    const { isItalic } = checkStylesForBoldOrItalic(styles);
    characterStyles = brandFont && brandFont.get('characterStyles');
    characterStyle = characterStyles && characterStyles.find(item => item.get('name') === characterStyleName);
    resultStyle.fontStyle = characterStyle && characterStyle.get('fontStyle');
    resultStyle.fontWeight = characterStyle && characterStyle.get('fontWeight');

    needToApplyArtificialItalic = isItalic
      && resultStyle.fontStyle !== inlineStylesUtils.getFontStyleFromStyle(Constants.InlineStylesForToggling.ITALIC);
    resultStyle.needToApplyArtificialItalic = needToApplyArtificialItalic;

    if (needToApplyArtificialItalic) {
      resultStyle.fontStyle = inlineStylesUtils.getFontStyleFromStyle(Constants.InlineStylesForToggling.ITALIC);
    }
  }

  if (!characterStyleNameStyle || !characterStyles || !characterStyle) {
    resultStyle.fontStyle =
      fontStyleStyle
        ? inlineStylesUtils.getFontStyleFromStyle(fontStyleStyle)
        : inlineStylesUtils.getFontStyleFromStyle(Constants.InlineStylesForToggling.NORMAL);

    resultStyle.fontWeight =
      fontWeightStyle
        ? inlineStylesUtils.getFontWeightFromStyle(fontWeightStyle)
        : inlineStylesUtils.getFontWeightFromStyle(Constants.InlineStylesForToggling.REGULAR);
  }

  if (fontColorStyle) {
    resultStyle.color = getFontColorFromFontColorStyle(fontColorStyle, colors);
  }

  if (hasSuperScriptStyle) {
    resultStyle = { ...Constants.CUSTOM_SCRIPT_STYLES[Constants.ScriptType.SUPERSCRIPT], ...resultStyle };
  }

  if (hasSubScriptStyle) {
    resultStyle = { ...Constants.CUSTOM_SCRIPT_STYLES[Constants.ScriptType.SUBSCRIPT], ...resultStyle };
  }

  if (bulletColorStyle) {
    resultStyle.bulletColor = getBulletColorFromFontColorStyle(bulletColorStyle, colors);
  }

  return resultStyle;
};

export const getCustomInlineStyleForFontStyleAndWeightOnly = (styles: Draft.DraftInlineStyle): Models.CSSInlineStyle => {
  const fontStyleStyle = inlineStylesUtils.findFontStyleStyle(styles);
  const fontWeightStyle = inlineStylesUtils.findFontWeightStyle(styles);

  const fontStyle = fontStyleStyle
    ? inlineStylesUtils.getFontStyleFromStyle(fontStyleStyle)
    : inlineStylesUtils.getFontStyleFromStyle(Constants.InlineStylesForToggling.NORMAL);

  const fontWeight = fontWeightStyle
    ? inlineStylesUtils.getFontWeightFromStyle(fontWeightStyle)
    : inlineStylesUtils.getFontStyleFromStyle(Constants.InlineStylesForToggling.REGULAR);

  return {
    fontStyle,
    fontWeight,
  };
};

export const getReferenceCustomInlineStyle = (styles: Draft.DraftInlineStyle): Models.CSSInlineStyle => {
  const hasSuperScriptStyle = styles.includes(Constants.ScriptType.SUPERSCRIPT);
  const hasSubScriptStyle = styles.includes(Constants.ScriptType.SUBSCRIPT);

  if (hasSuperScriptStyle) {
    return Constants.CUSTOM_SCRIPT_STYLES[Constants.ScriptType.SUPERSCRIPT];
  }
  if (hasSubScriptStyle) {
    return Constants.CUSTOM_SCRIPT_STYLES[Constants.ScriptType.SUBSCRIPT];
  }

  return {};
};

export const getScriptFontsize = (fontSize: number): string => {
  return toPx(fontSize * Constants.SCRIPT_BASIC_FONT_REDUCTION, 2);
};

export const getFontSize = (styles: Draft.DraftInlineStyle): Models.CSSInlineStyle | null => {
  const fontSizeStyle = inlineStylesUtils.findFontSizeStyle(styles);
  const hasSuperScriptStyle = styles.includes(Constants.ScriptType.SUPERSCRIPT);
  const hasSubScriptStyle = styles.includes(Constants.ScriptType.SUBSCRIPT);

  if (fontSizeStyle) {
    const fontSizeNumber = inlineStylesUtils.getFontSizeFromStyle(fontSizeStyle);
    const fontSize = (hasSuperScriptStyle || hasSubScriptStyle)
      ? getScriptFontsize(fontSizeNumber)
      : toPx(fontSizeNumber);

    return { fontSize };
  }

  return null;
};

export const getNonScriptedFontSize = (blockCharList: List<Draft.CharacterMetadata>): Models.CSSInlineStyle | null => {
  let result = CharacterMetadata.removeStyle(blockCharList.first(), Constants.ScriptType.SUPERSCRIPT);
  result = CharacterMetadata.removeStyle(result, Constants.ScriptType.SUBSCRIPT);

  return getFontSize(result.getStyle());
};

export function resetEditorStateStyles<S extends Models.EditorDependentStyles>(styles: S): S {
  return _.chain(styles)
    .cloneDeep()
    .set('fontColor', [])
    .set('fontSize', [])
    .set('fontFamily', [])
    .set('fontStyle', [])
    .set('fontWeight', [])
    .set('characterStyleName', [])
    .set('lineHeight', {})
    .set(['alignment', 'horizontal'], {})
    .value();
}

export function isolateEditorStateStyles(editorState: Draft.EditorState): Models.EditorDependentStyles {
  const { bulletColor, fontColor, fontFamily, fontSize, fontStyle, fontWeight, characterStyleName } = isolateInlineStyles(editorState);
  const horizontal = getAlignmentMap(editorState);
  const lineHeight = getLineHeightMap(editorState);

  return {
    bulletColor,
    fontColor,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    characterStyleName,
    lineHeight,
    alignment: {
      horizontal,
    },
  };
}

export function applyEditorStateStyles<S extends Models.EditorDependentStyles>(editorState: Draft.EditorState, styles: S): Draft.EditorState {
  const {
    fontColor,
    bulletColor,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    characterStyleName,
    lineHeight,
    alignment: {
      horizontal,
    },
  } = styles;

  return _.flow(
    _editorState => applyInlineStyleRanges(_editorState, fontColor),
    _editorState => applyInlineStyleRanges(_editorState, fontFamily),
    _editorState => applyInlineStyleRanges(_editorState, fontSize),
    _editorState => applyInlineStyleRanges(_editorState, fontStyle),
    _editorState => applyInlineStyleRanges(_editorState, fontWeight),
    _editorState => applyInlineStyleRanges(_editorState, characterStyleName),
    _editorState => applyInlineStyleRanges(_editorState, bulletColor),
    _editorState => applyLineHeight(_editorState, lineHeight),
    _editorState => applyAlignment(_editorState, horizontal),
  )(editorState);
}

export function ensureEmptyUnorderedListStyle(
  blockKey: string,
  contentState: Draft.ContentState,
  editorState: Draft.EditorState,
): Draft.ContentState {
  let newContentState = contentState;
  if (newContentState.getIn(['blockMap', blockKey, 'characterList']).size === 0) {
    newContentState = newContentState.setIn(
      ['blockMap', blockKey, 'characterList'],
      List([CharacterMetadata.create({ style: editorState.getCurrentInlineStyle() })]),
    ) as Draft.ContentState;
  }

  return newContentState;
}

function getColorByHex(colors: Models.BrandColorsList, colorHEX: string) {
  return colors.find(color =>
    Constants.colorTints
      .map(tint => colorWithTint(color, tint))
      .some(color => color.get('HEX') === colorHEX));
}

export function getBrandColor(
  colors: Models.BrandColorsList,
  colorHexOrName: string,
  tint?: string | number,
): Models.BrandColorMap | undefined {
  let baseColor = colors.find(color => color?.get('name') === colorHexOrName);

  if (!baseColor) {
    baseColor = getColorByHex(colors, colorHexOrName);
  }

  return baseColor && colorWithTint(baseColor, tint);
}

export const getFontColorFromFontColorStyle = (
  fontColorStyle: Partial<Models.BrandColor>,
  colors: Models.BrandColorsList,
): string | undefined => {
  const { name = '', HEX = '', tint } = fontColorStyle;
  const color = getBrandColor(colors, name || HEX, tint);

  return color ? color.get('HEX') : HEX;
};
