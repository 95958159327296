import { onLocationChanged } from 'connected-react-router/immutable';
import { Location } from 'history';
import _ from 'lodash';
import { put, spawn } from 'redux-saga/effects';

import * as Constants from 'const';
import { setLastError } from 'containers/App/actions';
import { AssemblerError, AssemblerErrorType, NotificationWindowOptions } from 'models';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';

/**
 * Handles error occurred in sagas
 * @param error - any error occurred during saga execution
 * @param sagaName - `[folder_name].[saga_name]` string in order to recognize particular saga. Uses only for logging in console
 * @param actionName - the second parameter of `intlGet`. Uses to show what action failed for users. All these actions must be stored in `locales`
 * @param errorType - if pass this parameter, the error processes by this error type.
 *   Is used in order to all errors were gone through `handleSagaError`
 * @param redirect - if `true`, redirect to interstitial page in case of NO_INTERNET_CONNECTION error type
 */
export function* handleSagaError(error: AssemblerError, sagaName: string, actionName: string, errorType?: AssemblerErrorType, redirect = false) {
  const message = `Error occurred in '${sagaName}' saga.`;
  // eslint-disable-next-line no-console
  yield spawn([console, console.error], message, error);
  yield put(setLastError({ ...error, message: `${message} ${error.message}` }));

  const type = errorType || _.get(error, 'type');

  if (redirect && type === Constants.ErrorType.Assembler.NO_INTERNET_CONNECTION) {
    // HACK: connected-react-router doesn't handle `push` method properly
    // CALL_HISTORY_METHOD isn't handled by this library's Reducer
    // hence, there is no store update and page rerender
    yield put(onLocationChanged({ pathname: '/interstitial' } as Location, 'PUSH'));
  } else {
    const errorInfo = getErrorInfo(error, type, actionName);
    yield spawn([Notifications, Notifications.showNotification], errorInfo);
  }
}

function getErrorInfo(error: AssemblerError, errorType?: AssemblerErrorType, actionName?: string): NotificationWindowOptions {
  const action = actionName ? intlGet('Notification.Error.Action', actionName) : intlGet('Notification.Error', 'PerformThisAction');

  const result: NotificationWindowOptions = {
    title: intlGet('Notification.Error.Title', 'SomethingWentWrong'),
    message: intlGet('Notification.Error', 'UnableToPerformThisAction', { action }),
    helpType: Constants.ErrorHelpMessageType.CONTACT_SUPPORT,
    helpMessage: intlGet('Notification.PromoMatsError.HelpMessage', 'TryAgainLater'),
    helpLink: getHelpLink(error),
    issueReportAvailable: true,
    topPriority: errorType === Constants.ErrorType.PromoMats.INVALID_SESSION_ID,
  };

  if (!errorType) {
    return result;
  }

  if (isPromoMatsError(errorType)) {
    const errorMessage = Constants.PromoMatsErrorMessageByErrorType[errorType];
    const helpMessageErrorType = actionName === 'Upload' && errorType === Constants.ErrorType.PromoMats.INSUFFICIENT_ACCESS
      ? Constants.ErrorType.PromoMats.INSUFFICIENT_ACCESS_UPLOAD
      : errorType;
    const errorHelpMessage = Constants.PromoMatsErrorHelpMessageByErrorType[helpMessageErrorType];
    const message = errorMessage ? errorMessage() : errorType;
    const helpMessage = errorHelpMessage ? errorHelpMessage() : intlGet('Notification.PromoMatsError.HelpMessage', 'TryAgainLater');

    result.title = intlGet('Notification.PromoMatsError.Title', 'PromoMatsExperiencedProblem');
    result.message = intlGet('Notification.PromoMatsError.FullMessage', 'UnableToPerformThisAction', { action, message });
    result.helpMessage = helpMessage;
  } else {
    const errorInfo = Constants.ErrorInfoByErrorType[errorType];

    if (!errorInfo) {
      return result;
    }

    const { title, message, helpType } = errorInfo;
    result.title = title(action);
    result.message = message && message();
    result.helpType = helpType || Constants.ErrorHelpMessageType.HELP_OPTIONS;
    result.listOfErrorMessages = getErrorMessages(error);
  }

  return result;
}

function isPromoMatsError(errorType: AssemblerErrorType): errorType is Constants.ErrorType.PromoMats {
  return _.includes(Constants.ErrorType.PromoMats, errorType);
}

function getErrorMessages(error: AssemblerError): string[] {
  const errorType = error.type;

  if (errorType === Constants.ErrorType.Assembler.HIGH_IMAGES_DATA) {
    return [
      error.message,
    ];
  }

  if (errorType === Constants.ErrorType.Assembler.MISSING_TRANSLATED_IMAGES) {
    const missingImagePaths = error.message.split(':')[1];

    return missingImagePaths
      .trim()
      .replace(/[\[\]]/g, '')
      .split(',')
      .map(decodeURIComponent);
  }

  return [];
}

function getHelpLink(error: AssemblerError): string {
  const errorType = error.type;

  switch (errorType) {
    case Constants.ErrorType.Assembler.MISSING_TRANSLATED_IMAGES:
    case Constants.ErrorType.Assembler.INCORRECT_FOLDER_STRUCTURE:
      return Constants.IMPORT_TRANSLATION_PACKAGE_HELP;
    default:
      return Constants.ASSEMBLER_HELP;
  }
}
