import * as Constants from 'const';
import * as Models from 'models';

export function getColorFromBrandStyle(
  color: string,
  brandColors: Models.BrandColorsList,
  brandStyle?: Models.BrandStyleObjMap,
  brandFonts?: Models.BrandFontsList,
  isBullet = false,
): string {
  const colorType = isBullet ? Models.BrandStyleProp.BULLET_COLLOR : Models.BrandStyleProp.COLOR;
  let resultColor = !color && !!brandStyle
    ? brandStyle.get(colorType)
    : color;

  // if color in brandStyle is empty try to use color that mentioned in brandFont "fontColor" prop
  if (brandStyle && brandFonts && !color) {
    const fontFamily = brandStyle.get(Models.BrandStyleProp.FONT_FAMILY);
    if (fontFamily.startsWith(Constants.EXTERNAL_REF_KEY)) {
      const [fontFamilyId] = fontFamily.split(Constants.ID_START_KEY)[1].split(Constants.ID_SEPARATOR_KEY);
      const brandFont = brandFonts.find(font => font.get('id') === fontFamilyId);
      const fontColorId = brandFont.get('fontColor');
      if (fontColorId) {
        const brandColor = brandColors.find(color => color.get('id') === fontColorId);
        resultColor = brandColor ? brandColor.get('HEX') : null;
      }
    }
  }

  if (color.startsWith(Constants.EXTERNAL_REF_KEY)) {
    const brandColorId = color.split(Constants.ID_START_KEY)[1];
    const brandColor = brandColors.find(color => color.get('id') === brandColorId);
    resultColor = brandColor ? brandColor.get('HEX') : null;
  }

  return resultColor;
}
