export enum IconType {
  ABBREVIATION = 'Abbreviation',
  ADD = 'Add',
  ADD_SLIM = 'AddSlim',
  ALIGN_BOTTOM_CENTER = 'AlignBottomCenter',
  ALIGN_BOTTOM_LEFT = 'AlignBottomLeft',
  ALIGN_BOTTOM_RIGHT = 'AlignBottomRight',
  ALIGN_CENTER_CENTER = 'AlignCenterCenter',
  ALIGN_CENTER_LEFT = 'AlignCenterLeft',
  ALIGN_CENTER_RIGHT = 'AlignCenterRight',
  ALIGN_TOP_CENTER = 'AlignTopCenter',
  ALIGN_TOP_LEFT = 'AlignTopLeft',
  ALIGN_TOP_RIGHT = 'AlignTopRight',
  ALT_TAG = 'AltTag',
  ANCHOR = 'Anchor',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_UP = 'ArrowUp',
  ARROW_UP_RIGHT = 'ArrowUpRight',
  ARROW_DOWN_RIGHT = 'ArrowDownRight',
  ARROW_ROTATE = 'ArrowCircular',
  BORDER_RADIUS = 'BorderRadius',
  CHECK_MARK = 'CheckMark',
  CHEVRON_DOWN = 'ChevronDown',
  CHEVRON_UP = 'ChevronUp',
  CLEAR_ALL_FORMATTING = 'ClearAllFormatting',
  CLOSE = 'Close',
  CLOSE_BOLD = 'CloseBold',
  COLLAPSE = 'Collapse',
  COLUMN_WIDTH = 'ColumnWidth',
  CONTENT = 'Content',
  CONTENT_COPY = 'ContentCopy',
  COPY = 'Copy',
  CUSTOM_SCREEN_FORMAT = 'CustomScreenFormat',
  DELETE = 'Delete',
  DELETE_BIN = 'DeleteBin',
  DESKTOP_SCREEN = 'DesktopScreen',
  DOUBLE_ARROW_AUTO = 'DoubleArrowAuto',
  DOUBLE_ARROW_LEFT = 'DoubleArrowLeft',
  DOUBLE_ARROW_RIGHT = 'DoubleArrowRight',
  DRAG = 'Drag',
  DRAG_DOTS = 'DragDots',
  EDIT = 'Edit',
  EXCLAMATION_MARK = 'ExclamationMark',
  EXPORT_PROJECT = 'ExportProject',
  FILL_COLOR_UNDERLINED = 'FillColorUnderlined',
  FIT_TO_CELL = 'FitToCell',
  GEAR = 'Gear',
  GROUP = 'Group',
  GROUP_LAYOUT = 'GroupLayout',
  HORIZONTAL_ALIGNMENT_CENTER = 'HorizontalAlignmentCenter',
  HORIZONTAL_ALIGNMENT_LEFT = 'HorizontalAlignmentLeft',
  HORIZONTAL_ALIGNMENT_RIGHT = 'HorizontalAlignmentRight',
  IMAGE = 'Image',
  IMAGE_MOBILE_SETTINGS = 'ImageMobileSettings',
  LAYOUT = 'LAYOUT',
  LINE_HEIGHT_CONTROL = 'LineHeightControl',
  LINE_HEIGHT_ONE = 'LineHeightOne',
  LINE_HEIGHT_ONE_POINT_FIVE = 'LineHeightOnePointFive',
  LINE_HEIGHT_ONE_POINT_TWO = 'LineHeightOnePointTwo',
  LINE_HEIGHT_TWO = 'LineHeightTwo',
  LINK = 'Link',
  LIST = 'List',
  MENU = 'Menu',
  MOVE = 'Move',
  NOTICE = 'Notice',
  NO_WRAP = 'NoWrap',
  PADDING = 'Padding',
  PADDING_INTERNAL = 'PaddingInternal',
  PHONE_SCREEN = 'PhoneScreen',
  PLUS = 'Plus',
  PLUS_FILLED = 'PlusFilled',
  QUESTION_MARK = 'QuestionMark',
  PREDEFINED_SCREEN_FORMAT = 'PredefinedScreenFormat',
  PREVIEW_HOVER = 'PreviewHover',
  REFERENCE_CITATION = 'ReferenceCitation',
  REFRESH = 'Refresh',
  RESIZE_SPACER = 'ResizeSpacer',
  RESPONSIVE = 'Responsive',
  SAVE_LAYOUT = 'SaveReusableLayout',
  SAVE_PROJECT = 'SaveProject',
  SCROLLABLE = 'Scrollable',
  SETTINGS = 'Settings',
  SPINNER = 'Spinner',
  SSI = 'SSI',
  STORY_CARD = 'StoryCard',
  TABLET_SCREEN = 'TabletScreen',
  TEXT = 'Text',
  TEXT_BOTTOM = 'TextBottom',
  TEXT_CENTER = 'TextCenter',
  TEXT_COLLECTION = 'TextCollection',
  TEXT_TOP = 'TextTop',
  THUMBNAIL_PREVIEW = 'ThumbnailPreview',
  TICK = 'Tick',
  TOOLBAR_BOLD = 'ToolbarBold',
  TOOLBAR_BORDER = 'ToolbarBorder',
  TOOLBAR_CENTER = 'ToolbarCenter',
  TOOLBAR_FILL_COLOR = 'ToolbarFillColor',
  TOOLBAR_FONT_COLOR = 'ToolbarFontColor',
  TOOLBAR_ITALIC = 'ToolbarItalic',
  TOOLBAR_JUSTIFY = 'ToolbarJustify',
  TOOLBAR_LEFT = 'ToolbarLeft',
  TOOLBAR_ORDERED_LIST = 'ToolbarOrderedList',
  TOOLBAR_RESET_COLOR = 'ToolbarResetColor',
  TOOLBAR_RIGHT = 'ToolbarRight',
  TOOLBAR_SUBSCRIPT = 'ToolbarSubscript',
  TOOLBAR_SUPERSCRIPT = 'ToolbarSuperscript',
  TOOLBAR_UNDERLINE = 'ToolbarUnderline',
  TOOLBAR_UNORDERED_LIST = 'ToolbarUnorderedList',
  TOOLBAR_MOBILE_IMAGE = 'ToolbarMobileImage',
  TRANSLATION = 'TRANSLATION',
  UNGROUP = 'Ungroup',
  UNLINK = 'Unlink',
  UPLOAD_PROJECT = 'UploadProject',
  UP_AND_DOWN_ARROWS = 'UpAndDownArrows',
  VERTICAL_ALIGNMENT_BOTTOM = 'VerticalAlignmentBottom',
  VERTICAL_ALIGNMENT_CENTER = 'VerticalAlignmentCenter',
  VERTICAL_ALIGNMENT_TOP = 'VerticalAlignmentTop',
  VIEW_DETAILS = 'ViewDetails',
  ZOOM_MINUS = 'ZoomMinus',
  ZOOM_PLUS = 'ZoomPlus',
  UNDO = 'Undo',
  REDO = 'Redo',
}
