import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { CharacterStyleMap } from 'models';
import { getCharacterStyle } from 'utils/brandStyles';
import { getFontFamilyFromInlineStyles } from 'utils/editor';
import { getCharacterStyleNameStyle, getFontFamilyStyle } from 'utils/inlineStyles';
import { FontProps } from './models';
import styles from './styles.module.scss';

const Font: React.FunctionComponent<FontProps> = (props) => {
  const { activeFontFamily, font, fonts, toggleFontFamily } = props;
  const characterStyles = font.get('characterStyles');
  const fontName = font.get('name');
  const fontFamilyStyle = getFontFamilyStyle(fontName);
  const transformedFontFamily = getFontFamilyFromInlineStyles(fontFamilyStyle, null, fonts, { returnAsString: true }, true);
  const hasCharacterStyles = characterStyles && characterStyles.size !== 0;
  const charStyleForFontClick = getCharacterStyle(characterStyles);
  const isActiveFont = activeFontFamily.fontFamily === fontName;

  const handleClick = (charStyle: CharacterStyleMap): () => void => () => {
    toggleFontFamily(font, charStyle);
  };

  return (
    <div className={styles.Font}>
      <div
        className={classNames(styles.fontNameWrapper, { [styles.active]: isActiveFont })}
        onClick={handleClick(charStyleForFontClick)}
        style={{ fontFamily: transformedFontFamily }}
      >
        <div>{fontName}</div>
        {hasCharacterStyles &&
          <div className={styles.fontListChevron}>
            <Icon type={IconType.CHEVRON_DOWN} size="badge" color={isActiveFont ? 'primary' : 'secondary'} />
          </div>
        }
      </div>
      {hasCharacterStyles &&
        <div className={styles.charStylesList}>
          {
            characterStyles.map((charStyle) => {
              const charStyleName = charStyle.get('name');
              const currentCharacterStyleNameStyle = getCharacterStyleNameStyle(charStyleName);
              const updatedFontFamily = getFontFamilyFromInlineStyles(fontFamilyStyle, currentCharacterStyleNameStyle, fonts);
              const fontStyle = charStyle.get('fontStyle');
              const fontWeight = Number(charStyle.get('fontWeight'));
              const isActive = isActiveFont && charStyleName === activeFontFamily.characterStyleName;

              return (
                <div
                  className={classNames(styles.charStyle, { [styles.active]: isActive })}
                  key={charStyleName}
                  style={{ fontFamily: updatedFontFamily, fontStyle, fontWeight }}
                  onClick={handleClick(charStyle)}
                >
                  {charStyleName}
                </div>
              );
            })
          }
        </div>
      }
    </div>
  );
};

export default Font;
