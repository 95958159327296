import React from 'react';

import ScreenSelect from 'components/ScreensPanelItem/components/Shared/ScreenSelect';
import { Option } from 'components/ScreensPanelItem/components/Shared/ScreenSelect/models';
import { ScreenMap } from 'models';
import { ScreenTypeProps } from './models';

const ScreenType: React.FunctionComponent<ScreenTypeProps> = ({ screen, screenDefinitions, updateScreenType }) => {
  const currentScreenDefId = (screen as ScreenMap).get('screenDefinitionId');
  const currentScreenDef = screenDefinitions.get(currentScreenDefId);
  const currentScreenType = currentScreenDef.get('defaultName');

  const handleChange = (option: Option): void => {
    const screenDefId = option.value;

    updateScreenType(screen.get('id'), screenDefId);
  };

  const activeOption: Option = {
    value: currentScreenDefId,
    label: currentScreenType,
  };

  const options: Option[] = screenDefinitions.valueSeq().toArray().map((screenDef) => {
    const screenType = screenDef.get('defaultName');
    const screenDefId = screenDef.get('id');

    return {
      value: screenDefId,
      label: screenType,
    };
  });

  return (
    <ScreenSelect
      activeOption={activeOption}
      onChange={handleChange}
      options={options}
    />
  );
};

export default ScreenType;
