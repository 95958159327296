import Draft from 'draft-js';
import { useCallback, useRef } from 'react';
import { StackToggler } from 'components/ArtboardAssets/utils/stack';
import * as UndoRedoModels from 'containers/UndoRedoControl/models';
import { UndoRedoMiddleware, useUndoRedo } from 'hooks/useUndoRedo';
import { EditorProps, EditorSetters } from '../editor';
import { CallToActionStack } from '../undo';
import { useStyles } from './useStyles';

export type UndoMiddleware = UndoRedoMiddleware<CallToActionStack>;

type UndoHook = {
  isUndoDisabled: boolean;
  isRedoDisabled: boolean;
  undo: () => void;
  redo: () => void;
  undoStackMiddleware: UndoMiddleware;
  fillUndoStackIfEmpty: () => void;
};

type State = {
  undoStack: CallToActionStack[];
  redoStack: CallToActionStack[];
};

type EditorHook = {
  editorState: Draft.EditorState;
  onEditorChange: (editorState: Draft.EditorState) => void;
  props: EditorProps;
  setters: EditorSetters;
};

type OtherOptions = {
  isAutoFitContent: boolean;
  toggleAutoFitContent: () => void;
  link: string;
  toggleLink: (value: string) => void;
  cellHeight: number;
  cellWidth: number;
  getHeight: () => number;
  getWidth: () => number;
  toggleRowHeight: (newHeight: number) => number;
  toggleColumnWidth: (newWidth: number) => number;
};

export function useUndo(
  editMode: boolean,
  saveAppState: UndoRedoModels.ActionCreator.SaveAppState,
  cancel: UndoRedoModels.ActionCreator.Cancel,
  editorHook: EditorHook,
  stylesHook: ReturnType<typeof useStyles>,
  other : OtherOptions,
): UndoHook {

  const {
    editorState,
    onEditorChange,
    props: editorProps,
    setters: editorSetters,
  } = editorHook;
  const [styles, stylesSetters] = stylesHook;

  const currentStackData: CallToActionStack = {
    abbreviationID: editorProps.abbreviationID,
    alignment: styles.alignment,
    assetBackgroundColor: styles.assetBackgroundColor,
    assetBackgroundOpacity: styles.assetBackgroundOpacity,
    assetBackgroundGradient: styles.assetBackgroundGradient
      ? [styles.assetBackgroundGradient, styles.assetBackgroundColor]
      : [undefined, undefined],
    assetBorderRadius: styles.assetBorderRadius,
    assetPadding: styles.assetPadding,
    backgroundColor: styles.backgroundGradient ? undefined : styles.backgroundColor,
    backgroundColorOpacity: styles.backgroundColorOpacity,
    backgroundImage: styles.backgroundImage,
    backgroundGradient: styles.backgroundGradient
      ? [styles.backgroundGradient, styles.backgroundColor]
      : [undefined, undefined],
    border: styles.border,
    borderRadius: styles.borderRadius,
    isAutoFitContent: other.isAutoFitContent,
    fitToCell: styles.fitToCell,
    fontSize: editorProps.fontSize,
    link: other.link,
    padding: styles.padding,
    textAlignment: styles.textAlignment,
    cellHeight: other.cellHeight,
    cellWidth: other.cellWidth,
    height: other.getHeight(),
    width: other.getWidth(),
    editorState,
  };
  const currentStackDataRef = useRef(currentStackData);
  currentStackDataRef.current = currentStackData;

  const togglersMap: StackToggler<CallToActionStack> = {
    abbreviationID: editorSetters.abbreviationID,
    alignment: stylesSetters.alignment,
    assetBackgroundColor: stylesSetters.assetBackgroundColor,
    assetBackgroundOpacity: stylesSetters.assetBackgroundOpacity,
    assetBackgroundGradient: ([gradient, color]) => stylesSetters.assetBackgroundGradient(gradient, color),
    assetBorderRadius: stylesSetters.assetBorderRadius,
    assetPadding: stylesSetters.assetPadding,
    backgroundColor: stylesSetters.backgroundColor,
    backgroundColorOpacity: stylesSetters.backgroundColorOpacity,
    backgroundGradient: ([gradient, color]) => stylesSetters.backgroundGradient(gradient, color),
    backgroundImage: stylesSetters.backgroundImage,
    border: stylesSetters.border,
    borderRadius: stylesSetters.borderRadius,
    isAutoFitContent: other.toggleAutoFitContent,
    fitToCell: stylesSetters.fitToCell,
    editorState: onEditorChange,
    fontSize: editorSetters.fontSize,
    link: other.toggleLink,
    padding: stylesSetters.padding,
    textAlignment: stylesSetters.textAlignment,
    height: stylesSetters.height,
    width: stylesSetters.width,
    cellHeight: other.toggleRowHeight,
    cellWidth: other.toggleColumnWidth,
  };

  const {
    undoStack,
    redoStack,
    undo,
    redo,
    undoStackMiddleware,
    updateUndoRedoBeforeChange,
  } = useUndoRedo<CallToActionStack>({
    togglersMap,
    currentStackData,
    editMode,
    saveAppState,
    cancel,
  });

  const stateRef = useRef<State>({ undoStack, redoStack });
  stateRef.current = { undoStack, redoStack };

  const fillUndoStackIfEmpty = useCallback((): void => {
    if (!stateRef.current.undoStack.length) {
      updateUndoRedoBeforeChange(currentStackDataRef.current);
    }
  }, [updateUndoRedoBeforeChange]);

  return {
    isUndoDisabled: !undoStack.length,
    isRedoDisabled: !redoStack.length,
    undo: (): void => undo(currentStackData),
    redo: (): void => redo(currentStackData),
    fillUndoStackIfEmpty,
    undoStackMiddleware,
  };
}
