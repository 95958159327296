import Draft from 'draft-js';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';

export function getBulletColor<T extends boolean | undefined>(
  isEnabled: T,
  editorState: Draft.EditorState,
  colors: T extends true ? Models.BrandColorsList : unknown,
): T extends true ? Models.BrandColorMap | undefined : undefined {
  if (!isEnabled) {
    return undefined;
  }

  const bulletColorHEX = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.BULLET_COLOR) || '';
  const bulletColorTint = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.BULLET_COLOR_TINT) || 0;
  const bulletColorName = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.BULLET_COLOR_NAME) || '';
  const bulletColor = editorUtils.getBrandColor(
    colors as Models.BrandColorsList,
    bulletColorName || bulletColorHEX,
    bulletColorTint,
  );

  return bulletColor as T extends true ? Models.BrandColorMap | undefined : undefined;
}
