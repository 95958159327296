import * as Models from 'models';
import { Schemas } from 'services/ArtboardConverter/models';
import { removeScriptTag } from 'utils/removeScriptTag';
import { SegmentType } from '../constants';
import { getLinks } from '../utils/getLinks';
import { createPart } from './createPart';

export function createSegment(values: Partial<Schemas.Segment>): Schemas.Segment;
export function createSegment(
  values: Partial<Schemas.Segment>,
  entity: Draft.EntityInstance | undefined,
  surfaces: Models.ScreensOrderedMap
): Schemas.Segment;
export function createSegment(
  values: Partial<Schemas.Segment>,
  entity?: Draft.EntityInstance,
  surfaces?: Models.ScreensOrderedMap,
): Schemas.Segment {
  const {
    parts = [],
    type = SegmentType.TEXT,
  } = values;

  let links = {} as Schemas.Links;
  const entityData = entity?.getData() as { url: string } | undefined;
  if (entityData && entityData.url && surfaces) {
    links = getLinks(entityData.url, surfaces);
    if (links.href) {
      links.href = removeScriptTag(links.href);
    }
  }

  return {
    parts: parts.map(createPart),
    type,
    ...links,
  };
}
