import { showConfirmation } from './showConfirmation';
import { showMissingFonts } from './showMissingFonts';
import { showNotification } from './showNotification';
import { showNotUpdatedLayouts } from './showNotUpdatedLayouts';
import { success, warn } from './toasts';

export const Notifications = {
  // modal windows
  showConfirmation,
  showMissingFonts,
  showNotUpdatedLayouts,
  showNotification,

  // toasts
  success,
  warn,
};
