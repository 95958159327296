import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Notification from 'components/ModalWindows/Notification';
import * as Models from 'components/ModalWindows/Notification/models';
import { downloadIssueReport } from 'containers/App/actions';
import { hideModal } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';

const mapStateToProps = (state, props: Models.NotificationProps) => createStructuredSelector({
  options: modalWindowOptions(props.modalWindowId),
});

const mapDispatchToProps = {
  hideModal,
  downloadIssueReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
