import _ from 'lodash';

export function isNumeric(stringOrNumber: string | number): boolean {
  if (_.isNumber(stringOrNumber)) {
    return true;
  }

  if (_.isString(stringOrNumber)) {
    return !isNaN(Number(stringOrNumber)) && !isNaN(parseFloat(stringOrNumber));
  }

  return false;
}

function isValidNumericFontWeight(fontWeight): boolean {
  return !(!fontWeight || !isNumeric(fontWeight));
}

export default function compareFontFacesByWeight(fontFaceA, fontFaceB): number {
  const { fontWeight: fontWeightA } = fontFaceA;
  const { fontWeight: fontWeightB } = fontFaceB;

  if (!isValidNumericFontWeight(fontWeightA) || isValidNumericFontWeight(fontWeightB)) {
    return -1;
  }

  if (!isValidNumericFontWeight(fontWeightB) || isValidNumericFontWeight(fontWeightA)) {
    return 1;
  }

  return 0;
}
