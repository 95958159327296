import classNames from 'classnames';
import * as React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

import ScreenFormatSize from 'components/ScreenFormatSize';
import ScreenFormatType from 'components/ScreensPanelItem/components/ScreenFormatType';
import * as Constants from 'const';
import { intlGet } from 'utils/intlGet';
import { isScreenNameDuplicated, isScreenNameInvalid } from 'utils/screenNameValidator';
import { isCustomScreenFormatType, isScreenFormatValid } from 'utils/screens/isCustomScreenFormat';
import { IUpdateScreenProps, IUpdateScreenState } from './models';
import styles from './styles.module.scss';

const DefaultScreenFormat = Constants.PredefinedScreenFormats[0];

// TODO: Rewrite the component to a functional one and start using useScreenFormat hook
export default class UpdateScreen extends React.PureComponent<IUpdateScreenProps, IUpdateScreenState> {
  state: IUpdateScreenState = {
    height: DefaultScreenFormat.height,
    isNameInvalid: false,
    isScreenTypeNotSelected: this.props.screenDefinitions.size > 0,
    screenFormatType: DefaultScreenFormat.type,
    screenName: this.props.defaultScreenName,
    surfaceDefinitionId: this.props.activeSurfaceDefinitionId,
    width: DefaultScreenFormat.width,
  };

  private onSubmitClick = (): void => {
    const { screenName, surfaceDefinitionId } = this.state;
    const { onSubmit, hideModal, modalWindowId, projectType, screenDefinitions } = this.props;
    const { areScreensResizable } = Constants.ProjectsConfig[projectType];

    if (areScreensResizable) {
      onSubmit({
        name: screenName.trim(),
        surfaceDefinitionId: screenDefinitions.keySeq().first(), // TBC: How can user choose/change screen definition?
        ...this.getSelectedScreenFormat(),
      });
    } else {
      onSubmit({ name: screenName.trim(), surfaceDefinitionId });
    }

    hideModal(modalWindowId);
  };

  private getSelectedScreenFormat = () => {
    const { screenFormatType, height, width } = this.state;

    return isCustomScreenFormatType(screenFormatType)
      ? { type: screenFormatType, height, width }
      : Constants.PredefinedScreenFormatByType[screenFormatType];
  };

  private onCancelClick: React.MouseEventHandler = () => {
    const { hideModal, modalWindowId } = this.props;

    hideModal(modalWindowId);
  };

  private onScreenNameChange: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { screenNames } = this.props;
    const { value: screenName } = event.target;

    if (!isScreenNameInvalid(screenName)) {
      this.setState({
        screenName,
        isNameInvalid: !screenName.trim() || isScreenNameDuplicated(screenName, screenNames),
      });
    }
  };

  private onScreenFormatChange = (screenFormatType: Constants.ScreenFormatType) => {
    this.setState({ screenFormatType });

    if (!isCustomScreenFormatType(screenFormatType)) {
      const { width, height } = Constants.PredefinedScreenFormatByType[screenFormatType];
      this.onWidthChange(width);
      this.onHeightChange(height);
    }
  };

  private onScreenChange: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      surfaceDefinitionId: event.target.value,
      isScreenTypeNotSelected: !event.target.value,
    });
  };

  private renderScreenTypesDropdown = (): JSX.Element => (
    <div className={styles.dropdownContainer}>
      <InputGroup.Text className={styles.prefixText}>{intlGet('UpdateScreen', 'Type')}</InputGroup.Text>
      <Form.Control
        as="select"
        className={classNames(styles.inputField, styles.selectType)}
        onChange={this.onScreenChange}
        value={this.state.surfaceDefinitionId || ''}
      >
        <option value="">
          {intlGet('UpdateScreen.Type', 'ChooseScreenType')}
        </option>
        {
          this.props.screenDefinitions.valueSeq().map(screenDefinition => (
            <option
              key={screenDefinition.get('id')}
              value={screenDefinition.get('id')}
            >
              {screenDefinition.get('defaultName')}
            </option>
          ))
        }
      </Form.Control>
    </div>
  );

  private onWidthChange = (width: number): void => {
    this.setState({ width });
  };

  private onHeightChange = (height: number): void => {
    this.setState({ height });
  };

  private renderScreenFormatsControl = (): JSX.Element => {
    const { screenFormatType, width, height } = this.state;

    return (
      <>
        <div className={styles.dropdownContainer}>
          <InputGroup.Text className={styles.prefixText}>{intlGet('UpdateScreen', 'Type')}</InputGroup.Text>
          <ScreenFormatType
            className={classNames(styles.inputField, styles.selectType)}
            formatType={screenFormatType}
            onFormateChange={this.onScreenFormatChange}
          />
        </div>
        <div className={styles.inputContainer}>
          <InputGroup.Text className={styles.prefixText}>{intlGet('UpdateScreen', 'Size')}</InputGroup.Text>
          <ScreenFormatSize
            className={styles.sizeControl}
            height={height}
            isDisabled={!isCustomScreenFormatType(screenFormatType)}
            setHeight={this.onHeightChange}
            setWidth={this.onWidthChange}
            width={width}
          />
        </div>
      </>
    );
  };

  render() {
    const { screenName, isNameInvalid, isScreenTypeNotSelected } = this.state;
    const { submitButtonTitle, screenDefinitions, projectType } = this.props;
    const { areScreensResizable } = Constants.ProjectsConfig[projectType];
    const isScreenTypeInvalid = areScreensResizable ? !isScreenFormatValid(this.getSelectedScreenFormat()) : isScreenTypeNotSelected;
    const isSubmitButtonDisabled = isNameInvalid || isScreenTypeInvalid;

    return (
      <div className={styles.UpdateScreen}>
        <header className={styles.header}>
          <div>{this.props.title}</div>
        </header>
        <main className={styles.main}>
          <InputGroup className={styles.input}>
            <InputGroup.Text className={styles.prefixText}>{intlGet('UpdateScreen', 'Name')}</InputGroup.Text>
            <Form.Control
              value={screenName}
              onChange={this.onScreenNameChange}
              maxLength={Constants.MAX_SCREEN_NAME_LENGTH}
              placeholder={intlGet('UpdateScreen.Name', 'StartTyping')}
              className={classNames(styles.inputField, styles.inputName)}
            />
            {
              areScreensResizable
                ? this.renderScreenFormatsControl()
                : screenDefinitions.size > 0 && this.renderScreenTypesDropdown()
            }
          </InputGroup>
        </main>
        <footer className={styles.footer}>
          <Button onClick={this.onCancelClick} variant="secondary">{intlGet('UpdateScreen.Buttons', 'Cancel')}</Button>
          <Button onClick={this.onSubmitClick} variant="primary" disabled={isSubmitButtonDisabled}>{submitButtonTitle}</Button>
        </footer>
      </div>
    );
  }
}
