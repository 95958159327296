import Draft from 'draft-js';
import Immutable from 'immutable';
import * as _ from 'lodash';

import * as Constants from 'const';
import * as Models from 'models';
import { getBackgroundColorFromBrandStyle, getFontColorFromBrandStyle } from 'utils/brandStyles/getColorTypeFromBrandStyle';
import { boxPropertiesFromString } from 'utils/converters';
import {
  getBulletColorFromStyle,
  getCharacterStyleNameFromStyle,
  getFontColorFromStyle,
  getFontFamilyFromStyle,
  getFontSizeFromStyle,
  getPreferredColorStyle,
} from 'utils/inlineStyles';
import { getDesktopBrandStyles } from './getDesktopBrandStyles';
import { getFontFamilyFromBrandStyle } from './getFontFamilyFromBrandStyle';

export function getTextStylesFromBrandStyle(
  style: Models.BrandStyleMap,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): Models.TextBrandStyles {
  let backgroundColor: Models.BrandColorMap = null;
  let bulletColor: string = Constants.DefaultCustomStyle.FONT_COLOR;
  let characterStyle: string = null;
  let fontColor: string = Constants.DefaultCustomStyle.FONT_COLOR;
  let fontFamily: string = Constants.DefaultCustomStyle.FONT_FAMILY;
  let fontSize: number = Constants.DefaultCustomStyle.FONT_SIZE;
  let lineHeight = Constants.DefaultLineHeight;
  let padding = Immutable.Map(Constants.Styles.DefaultTextPadding);
  let textAlign = Constants.DefaultTextAlignment;
  let verticalAlignment = Constants.AssetAlignmentType.TOP;

  if (style) {
    const desktopStyles = getDesktopBrandStyles(style);

    // Variables section
    // Inline styles
    fontSize = parseInt(desktopStyles.get(Models.BrandStyleProp.FONT_SIZE), 10);
    fontColor = getFontColorFromBrandStyle(desktopStyles.get(Models.BrandStyleProp.COLOR), colors, desktopStyles, fonts);
    ({ fontFamily, characterStyle } = getFontFamilyFromBrandStyle(desktopStyles, fonts));

    // Paragraph styles
    lineHeight = Number(desktopStyles.get(Models.BrandStyleProp.LINE_HEIGHT));
    textAlign = desktopStyles.get(Models.BrandStyleProp.TEXT_ALIGN);

    // Cell styles
    backgroundColor = getBackgroundColorFromBrandStyle(colors, desktopStyles);
    bulletColor = desktopStyles.get(Models.BrandStyleProp.BULLET_COLLOR);
    padding = boxPropertiesFromString<Models.PaddingMap>(desktopStyles.get(Models.BrandStyleProp.PADDING));
    verticalAlignment = desktopStyles.get(Models.BrandStyleProp.VERTICAL_ALIGN);
  }

  return {
    backgroundColor,
    bulletColor,
    characterStyle,
    fontColor,
    fontFamily,
    fontSize,
    lineHeight,
    padding,
    textAlign,
    verticalAlignment,
  };
}

export const applyOverridenPadding = (
  brandStylePadding: Models.BoxProperty,
  sourceStylePadding: Models.BoxProperty,
  relation: Models.RegularRelation<Models.TextRelationStyles>,
) => {
  const relationPadding = relation.styles.padding;
  const overrideValues = {};

  if (!relationPadding) {
    return;
  }

  for (const [direction, value] of Object.entries(sourceStylePadding)) {
    if (value !== relationPadding[direction]) {
      overrideValues[direction] = relationPadding[direction];
    }
  }

  relation.styles.padding = Immutable.mergeDeep(brandStylePadding, overrideValues);
};

export const getStylesToMergeFromBrandStyles = (
  textComponent: Models.TextComponent,
  styles: Models.TextRelationStyles,
  sourceBrandStyleProps: Models.TextBrandStyles,
  brandStyleProps: Models.TextBrandStyles,
  colors: Models.BrandColorsList,
): string[] => {
  const {
    fontColor,
    bulletColor,
    fontSize,
    fontFamily,
    lineHeight,
    characterStyleName,
    backgroundColor,
    alignment: { horizontal },
    isAutoFitContent,
  } = styles;

  const rawContent: Draft.RawDraftContentState = textComponent ? JSON.parse(textComponent.rawContent) : null;
  const contentState: Draft.ContentState = rawContent ? Draft.convertFromRaw(rawContent) : Draft.ContentState.createFromBlockArray([]);
  const blockMap: Draft.BlockMap = contentState.getBlockMap();
  const blockKeys = blockMap.valueSeq().toArray().map(block => block.getKey());

  const bulletColorStyles = getInlineStylesFromBlocks(bulletColor, blockKeys);
  const characterStyleNameStyles = getInlineStylesFromBlocks(characterStyleName, blockKeys);
  const fontColorStyles = getInlineStylesFromBlocks(fontColor, blockKeys);
  const fontFamilyStyles = getInlineStylesFromBlocks(fontFamily, blockKeys);
  const fontSizeStyles = getInlineStylesFromBlocks(fontSize, blockKeys);
  const lineHeightStyles = getParagraphStylesFromBlocks(lineHeight, blockKeys) as Constants.TextLineHeightValue[];
  const textAlignStyles = getParagraphStylesFromBlocks(horizontal, blockKeys) as Constants.TextHorizontalAlignmentType[];

  let stylesToApply = [];

  if (backgroundColor && backgroundColor === sourceBrandStyleProps.fontColor) {
    stylesToApply.push(Models.TextBrandStyleField.BACKGROUND_COLOR);
  }

  if (
    bulletColorStyles.length > 0
    && isStyleAppliedForWholeTextComponent(bulletColorStyles)
    && (bulletColorStyles[0] === getPreferredColorStyle(sourceBrandStyleProps.bulletColor, colors, true)
    || getBulletColorFromStyle(bulletColorStyles[0]) === sourceBrandStyleProps.bulletColor)) {
    stylesToApply.push(Models.TextBrandStyleField.BULLET_COLOR);
  }

  if (
    characterStyleNameStyles.length > 0
    && isStyleAppliedForWholeTextComponent(characterStyleNameStyles)
    && getCharacterStyleNameFromStyle(characterStyleNameStyles[0]) === sourceBrandStyleProps.characterStyle) {
    stylesToApply.push(Models.TextBrandStyleField.CHARACTER_STYLE);
  }

  if (
    fontColorStyles.length > 0
    && isStyleAppliedForWholeTextComponent(fontColorStyles)
    && getFontColorFromStyle(fontColorStyles[0]) === sourceBrandStyleProps.fontColor) {
    stylesToApply.push(Models.TextBrandStyleField.FONT_COLOR);
  }

  if (
    fontSizeStyles.length > 0
    && isStyleAppliedForWholeTextComponent(fontSizeStyles)
    && Number(getFontSizeFromStyle(fontSizeStyles[0])) === sourceBrandStyleProps.fontSize) {
    stylesToApply.push(Models.TextBrandStyleField.FONT_SIZE);
  }

  if (
    fontFamilyStyles.length > 0
    && isStyleAppliedForWholeTextComponent(fontFamilyStyles)
    && getFontFamilyFromStyle(fontFamilyStyles[0]) === sourceBrandStyleProps.fontFamily) {
    stylesToApply.push(Models.TextBrandStyleField.FONT_FAMILY);
  }

  if (
    lineHeightStyles.length > 0
    && isStyleAppliedForWholeTextComponent(lineHeightStyles)
    && lineHeightStyles[0] === sourceBrandStyleProps.lineHeight) {
    stylesToApply.push(Models.TextBrandStyleField.LINE_HEIGHT);
  }

  if (
    textAlignStyles.length > 0
    && isStyleAppliedForWholeTextComponent(textAlignStyles)
    && textAlignStyles[0] === sourceBrandStyleProps.textAlign) {
    stylesToApply.push(Models.TextBrandStyleField.TEXT_ALIGN);
  }

  // applied to both old RL with no autofit and new when autofit is off
  if (!isAutoFitContent) {
    stylesToApply.push(Models.TextBrandStyleField.IS_AUTOFIT_CONTENT);
  }

  Object.keys(brandStyleProps).forEach((styleName) => {
    const styleValue = brandStyleProps[styleName];

    if (!styleValue || styleValue.length === 0) {
      stylesToApply = stylesToApply.filter(style => style !== styleName);
    }
  });

  return stylesToApply;
};

export function isStyleAppliedForWholeTextComponent(
  styles: (string | Constants.TextHorizontalAlignmentType | Constants.TextLineHeightValue)[],
): boolean {
  const uniqStyles = _(styles).compact().uniq().value();

  return _.size(uniqStyles) === 1;
}

export function getInlineStylesFromBlocks(styles: Models.StyleRanges, blockKeys: string[]): string[] {
  return _(styles)
    .filter(style => blockKeys.includes(style.blockKey))
    .map('style')
    .value();
}

export function getParagraphStylesFromBlocks(
  styles: Record<string, Constants.TextHorizontalAlignmentType> | Record<string, Constants.TextLineHeightValue>,
  blockKeys: string[],
): (Constants.TextHorizontalAlignmentType | Constants.TextLineHeightValue)[] {
  return _.filter(styles, (value, key) => blockKeys.includes(key));
}
