import Abbreviation from 'assets/images/Abbreviation';
import Add from 'assets/images/Add';
import AddSlim from 'assets/images/AddSlim';
import AlignBottomCenter from 'assets/images/AlignmentBottomCenter';
import AlignBottomLeft from 'assets/images/AlignmentBottomLeft';
import AlignBottomRight from 'assets/images/AlignmentBottomRight';
import AlignCenterCenter from 'assets/images/AlignmentCenterCenter';
import AlignCenterLeft from 'assets/images/AlignmentCenterLeft';
import AlignCenterRight from 'assets/images/AlignmentCenterRight';
import AlignTopCenter from 'assets/images/AlignmentTopCenter';
import AlignTopLeft from 'assets/images/AlignmentTopLeft';
import AlignTopRight from 'assets/images/AlignmentTopRight';
import AltTag from 'assets/images/AltTag';
import Anchor from 'assets/images/Anchor';
import ArrowDownRight from 'assets/images/ArrowDownRight';
import ArrowLeft from 'assets/images/ArrowLeft';
import ArrowRight from 'assets/images/ArrowRight';
import ArrowRotate from 'assets/images/ArrowRotate';
import ArrowUp from 'assets/images/ArrowUp';
import ArrowUpRight from 'assets/images/ArrowUpRight';
import BorderRadius from 'assets/images/BorderRadius';
import CheckMark from 'assets/images/CheckMark';
import ChevronDown from 'assets/images/ChevronDown';
import ChevronUp from 'assets/images/ChevronUp';
import ClearAllFormatting from 'assets/images/ClearAllFormatting';
import CloseBold from 'assets/images/CloseBold';
import CloseIcon from 'assets/images/CloseIcon';
import Collapse from 'assets/images/Collapse';
import ColumnWidth from 'assets/images/ColumnWidth';
import Content from 'assets/images/Content';
import ContentCopy from 'assets/images/ContentCopy';
import Copy from 'assets/images/Copy';
import CustomScreenFormat from 'assets/images/CustomScreenFormat';
import Delete from 'assets/images/Delete';
import DeleteBin from 'assets/images/DeleteBin';
import DesktopScreen from 'assets/images/DesktopScreen';
import DoubleArrowAuto from 'assets/images/DoubleArrowAuto';
import DoubleArrowLeft from 'assets/images/DoubleArrowLeft';
import DoubleArrowRight from 'assets/images/DoubleArrowRight';
import DragDots from 'assets/images/DragDots';
import Edit from 'assets/images/Edit';
import ExclamationMark from 'assets/images/ExclamationMark';
import ExportProject from 'assets/images/ExportProject';
import FillColorUnderlined from 'assets/images/FillColorUnderlined';
import FitToCell from 'assets/images/FitToCell';
import Gear from 'assets/images/Gear';
import GrabBar from 'assets/images/GrabBar';
import Group from 'assets/images/Group';
import GroupLayout from 'assets/images/GroupLayout';
import HorizontalAlignmentCenter from 'assets/images/HorizontalAlignmentCenter';
import HorizontalAlignmentLeft from 'assets/images/HorizontalAlignmentLeft';
import HorizontalAlignmentRight from 'assets/images/HorizontalAlignmentRight';
import Image from 'assets/images/Image';
import ImageMobileSettings from 'assets/images/ImageMobileSettings';
import Layout from 'assets/images/Layout';
import LineHeightControl from 'assets/images/LineHeight';
import LineHeightOne from 'assets/images/LineHeightOne';
import LineHeightOnePointFive from 'assets/images/LineHeightOnePointFive';
import LineHeightOnePointTwo from 'assets/images/LineHeightOnePointTwo';
import LineHeightTwo from 'assets/images/LineHeightTwo';
import Link from 'assets/images/Link';
import List from 'assets/images/List';
import Menu from 'assets/images/Menu';
import Move from 'assets/images/Move';
import Notice from 'assets/images/Notice';
import NoWrap from 'assets/images/NoWrap';
import Padding from 'assets/images/Padding';
import PaddingInternal from 'assets/images/PaddingInternal';
import PhoneScreen from 'assets/images/PhoneScreen';
import Plus from 'assets/images/Plus';
import PlusFilled from 'assets/images/PlusFilled';
import PredefinedScreenFormat from 'assets/images/PredefinedScreenFormat';
import PreviewHover from 'assets/images/PreviewHover';
import QuestionMark from 'assets/images/QuestionMark';
import Redo from 'assets/images/Redo';
import ReferenceCitation from 'assets/images/ReferenceCitation';
import Refresh from 'assets/images/Refresh';
import ResizeSpacerIcon from 'assets/images/ResizeSpacerIcon';
import Responsive from 'assets/images/Responsive';
import SaveProject from 'assets/images/SaveProject';
import SaveReusableLayout from 'assets/images/SaveReusableLayout';
import Scrollable from 'assets/images/Scrollable';
import Settings from 'assets/images/Settings';
import Spinner from 'assets/images/Spinner';
import SSI from 'assets/images/SSI';
import StoryCard from 'assets/images/StoryCard';
import TabletScreen from 'assets/images/TabletScreen';
import Text from 'assets/images/Text';
import TextBottom from 'assets/images/TextBottom';
import TextCenter from 'assets/images/TextCenter';
import TextCollection from 'assets/images/TextCollection';
import TextTop from 'assets/images/TextTop';
import ThumbnailPreview from 'assets/images/ThumbnailPreview';
import Tick from 'assets/images/Tick';
import ToolbarBold from 'assets/images/ToolbarBold';
import ToolbarBorder from 'assets/images/ToolbarBorder';
import ToolbarCenter from 'assets/images/ToolbarCenter';
import ToolbarFillColor from 'assets/images/ToolbarFillColor';
import ToolbarFontColor from 'assets/images/ToolbarFontColor';
import ToolbarItalic from 'assets/images/ToolbarItalic';
import ToolbarJustify from 'assets/images/ToolbarJustify';
import ToolbarLeft from 'assets/images/ToolbarLeft';
import ToolbarOrderedList from 'assets/images/ToolbarOrderedList';
import ToolbarResetColor from 'assets/images/ToolbarResetColor';
import ToolbarRight from 'assets/images/ToolbarRight';
import ToolbarSubscript from 'assets/images/ToolbarSubscript';
import ToolbarSuperscript from 'assets/images/ToolbarSuperscript';
import ToolbarUnderline from 'assets/images/ToolbarUnderline';
import ToolbarUnorderedList from 'assets/images/ToolbarUnorderedList';
import Translation from 'assets/images/Translation';
import Undo from 'assets/images/Undo';
import Ungroup from 'assets/images/Ungroup';
import Unlink from 'assets/images/Unlink';
import UpAndDownArrows from 'assets/images/UpAndDownArrows';
import UploadProject from 'assets/images/UploadProject';
import VerticalAlignmentBottom from 'assets/images/VerticalAlignmentBottom';
import VerticalAlignmentCenter from 'assets/images/VerticalAlignmentCenter';
import VerticalAlignmentTop from 'assets/images/VerticalAlignmentTop';
import ViewDetails from 'assets/images/ViewDetails';
import ZoomMinus from 'assets/images/ZoomMinus';
import ZoomPlus from 'assets/images/ZoomPlus';

import { IconType } from './constants';

export default {
  [IconType.ABBREVIATION]: Abbreviation,
  [IconType.ADD]: Add,
  [IconType.ADD_SLIM]: AddSlim,
  [IconType.ALIGN_BOTTOM_CENTER]: AlignBottomCenter,
  [IconType.ALIGN_BOTTOM_LEFT]: AlignBottomLeft,
  [IconType.ALIGN_BOTTOM_RIGHT]: AlignBottomRight,
  [IconType.ALIGN_CENTER_CENTER]: AlignCenterCenter,
  [IconType.ALIGN_CENTER_LEFT]: AlignCenterLeft,
  [IconType.ALIGN_CENTER_RIGHT]: AlignCenterRight,
  [IconType.ALIGN_TOP_CENTER]: AlignTopCenter,
  [IconType.ALIGN_TOP_LEFT]: AlignTopLeft,
  [IconType.ALIGN_TOP_RIGHT]: AlignTopRight,
  [IconType.ALT_TAG]: AltTag,
  [IconType.ANCHOR]: Anchor,
  [IconType.ARROW_LEFT]: ArrowLeft,
  [IconType.ARROW_RIGHT]: ArrowRight,
  [IconType.ARROW_UP]: ArrowUp,
  [IconType.ARROW_UP_RIGHT]: ArrowUpRight,
  [IconType.ARROW_DOWN_RIGHT]: ArrowDownRight,
  [IconType.ARROW_ROTATE]: ArrowRotate,
  [IconType.BORDER_RADIUS]: BorderRadius,
  [IconType.CHECK_MARK]: CheckMark,
  [IconType.CHEVRON_DOWN]: ChevronDown,
  [IconType.CHEVRON_UP]: ChevronUp,
  [IconType.CLEAR_ALL_FORMATTING]: ClearAllFormatting,
  [IconType.CLOSE]: CloseIcon,
  [IconType.CLOSE_BOLD]: CloseBold,
  [IconType.COLLAPSE]: Collapse,
  [IconType.COLUMN_WIDTH]: ColumnWidth,
  [IconType.CONTENT]: Content,
  [IconType.CONTENT_COPY]: ContentCopy,
  [IconType.COPY]: Copy,
  [IconType.CUSTOM_SCREEN_FORMAT]: CustomScreenFormat,
  [IconType.DELETE]: Delete,
  [IconType.DELETE_BIN]: DeleteBin,
  [IconType.DESKTOP_SCREEN]: DesktopScreen, // TODO: do we need this icon?
  [IconType.DOUBLE_ARROW_AUTO]: DoubleArrowAuto,
  [IconType.DOUBLE_ARROW_LEFT]: DoubleArrowLeft,
  [IconType.DOUBLE_ARROW_RIGHT]: DoubleArrowRight,
  [IconType.DRAG]: GrabBar,
  [IconType.DRAG_DOTS]: DragDots,
  [IconType.EDIT]: Edit,
  [IconType.EXCLAMATION_MARK]: ExclamationMark,
  [IconType.EXPORT_PROJECT]: ExportProject,
  [IconType.FILL_COLOR_UNDERLINED]: FillColorUnderlined,
  [IconType.FIT_TO_CELL]: FitToCell,
  [IconType.GEAR]: Gear,
  [IconType.GROUP]: Group,
  [IconType.GROUP_LAYOUT]: GroupLayout,
  [IconType.HORIZONTAL_ALIGNMENT_CENTER]: HorizontalAlignmentCenter,
  [IconType.HORIZONTAL_ALIGNMENT_LEFT]: HorizontalAlignmentLeft,
  [IconType.HORIZONTAL_ALIGNMENT_RIGHT]: HorizontalAlignmentRight,
  [IconType.IMAGE]: Image,
  [IconType.IMAGE_MOBILE_SETTINGS]: ImageMobileSettings,
  [IconType.LAYOUT]: Layout,
  [IconType.LINE_HEIGHT_CONTROL]: LineHeightControl,
  [IconType.LINE_HEIGHT_ONE]: LineHeightOne,
  [IconType.LINE_HEIGHT_ONE_POINT_FIVE]: LineHeightOnePointFive,
  [IconType.LINE_HEIGHT_ONE_POINT_TWO]: LineHeightOnePointTwo,
  [IconType.LINE_HEIGHT_TWO]: LineHeightTwo,
  [IconType.LINK]: Link,
  [IconType.LIST]: List,
  [IconType.MENU]: Menu,
  [IconType.MOVE]: Move,
  [IconType.NOTICE]: Notice,
  [IconType.NO_WRAP]: NoWrap,
  [IconType.PADDING]: Padding,
  [IconType.PADDING_INTERNAL]: PaddingInternal,
  [IconType.PHONE_SCREEN]: PhoneScreen, // TODO: do we need this icon?
  [IconType.PLUS]: Plus,
  [IconType.PLUS_FILLED]: PlusFilled,
  [IconType.PREDEFINED_SCREEN_FORMAT]: PredefinedScreenFormat,
  [IconType.PREVIEW_HOVER]: PreviewHover,
  [IconType.QUESTION_MARK]: QuestionMark,
  [IconType.REFERENCE_CITATION]: ReferenceCitation,
  [IconType.REFRESH]: Refresh,
  [IconType.RESIZE_SPACER]: ResizeSpacerIcon,
  [IconType.RESPONSIVE]: Responsive,
  [IconType.SAVE_LAYOUT]: SaveReusableLayout,
  [IconType.SAVE_PROJECT]: SaveProject,
  [IconType.SCROLLABLE]: Scrollable,
  [IconType.SETTINGS]: Settings,
  [IconType.SPINNER]: Spinner,
  [IconType.SSI]: SSI,
  [IconType.STORY_CARD]: StoryCard,
  [IconType.TABLET_SCREEN]: TabletScreen,
  [IconType.TEXT]: Text,
  [IconType.TEXT_BOTTOM]: TextBottom,
  [IconType.TEXT_CENTER]: TextCenter,
  [IconType.TEXT_COLLECTION]: TextCollection,
  [IconType.TEXT_TOP]: TextTop,
  [IconType.THUMBNAIL_PREVIEW]: ThumbnailPreview,
  [IconType.TICK]: Tick,
  [IconType.TOOLBAR_BOLD]: ToolbarBold,
  [IconType.TOOLBAR_BORDER]: ToolbarBorder,
  [IconType.TOOLBAR_CENTER]: ToolbarCenter,
  [IconType.TOOLBAR_FILL_COLOR]: ToolbarFillColor,
  [IconType.TOOLBAR_FONT_COLOR]: ToolbarFontColor,
  [IconType.TOOLBAR_ITALIC]: ToolbarItalic,
  [IconType.TOOLBAR_JUSTIFY]: ToolbarJustify,
  [IconType.TOOLBAR_LEFT]: ToolbarLeft,
  [IconType.TOOLBAR_ORDERED_LIST]: ToolbarOrderedList, // TODO: do we need this icon?
  [IconType.TOOLBAR_RESET_COLOR]: ToolbarResetColor,
  [IconType.TOOLBAR_RIGHT]: ToolbarRight,
  [IconType.TOOLBAR_SUBSCRIPT]: ToolbarSubscript,
  [IconType.TOOLBAR_SUPERSCRIPT]: ToolbarSuperscript,
  [IconType.TOOLBAR_UNDERLINE]: ToolbarUnderline,
  [IconType.TOOLBAR_UNORDERED_LIST]: ToolbarUnorderedList,
  [IconType.TOOLBAR_MOBILE_IMAGE]: ExclamationMark,
  [IconType.TRANSLATION]: Translation,
  [IconType.UNGROUP]: Ungroup,
  [IconType.UNLINK]: Unlink,
  [IconType.UPLOAD_PROJECT]: UploadProject,
  [IconType.UP_AND_DOWN_ARROWS]: UpAndDownArrows,
  [IconType.VERTICAL_ALIGNMENT_BOTTOM]: VerticalAlignmentBottom,
  [IconType.VERTICAL_ALIGNMENT_CENTER]: VerticalAlignmentCenter,
  [IconType.VERTICAL_ALIGNMENT_TOP]: VerticalAlignmentTop,
  [IconType.VIEW_DETAILS]: ViewDetails,
  [IconType.ZOOM_MINUS]: ZoomMinus,
  [IconType.ZOOM_PLUS]: ZoomPlus,
  [IconType.UNDO]: Undo,
  [IconType.REDO]: Redo,
} as Record<IconType, React.FunctionComponent>;
